import { createSlice } from "@reduxjs/toolkit"
import { getData, postData } from "../utils/connection-utils"
import { alertActions } from "./alert-slice"

const configSlice = createSlice({
    name: "config",
    initialState: {
        data: [],
        error: "",
        loaded: false,
        updated: false
    },
    reducers: {
        updateConfig(state, action) {
            state.data = action.payload.data || []
            state.error = action.payload.error
            state.loaded = action.payload.loaded
            state.updated = action.payload.updated
        },
        clearConfig(state) {
            state.data = []
            state.error = ""
            state.loaded = false
            state.updated = false
        }
    }
})
export const fetchConfigDataAll = (sportId) => {
    return async (dispatch) => {
        const fetchDataAll = async () => {
            const data = await getData(`${process.env.REACT_APP_BACKEND_URL}/${sportId}/config/_all/get`)
            return data
        }

        const configData = await fetchDataAll()
        dispatch(
            configActions.updateConfig({
                data: !configData.error ? configData : [],
                loaded: true,
                error: configData.error || "",
                updated: false
            })
        )
    }
}
export const fetchConfigData = (sportId, eventStatus) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const data = await getData(`${process.env.REACT_APP_BACKEND_URL}/${sportId}/config/${eventStatus}/get`, {
                headers: {
                    Authorization: localStorage.getItem("access_token") ? "Bearer " + localStorage.getItem("access_token") : null,
                    "Content-Type": "application/json",
                    accept: "application/json"
                },
                updateAccessToken: true
            })
            return data
        }

        const configData = await fetchData()
        dispatch(
            configActions.updateConfig({
                data: !configData.error ? configData : [],
                loaded: true,
                error: configData.error || "",
                updated: false
            })
        )
    }
}
export const fetchConfigDataById = (sportId, eventStatus, configId) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const data = await getData(`${process.env.REACT_APP_BACKEND_URL}/${sportId}/config/${eventStatus}/${configId}/get`, {
                headers: {
                    Authorization: localStorage.getItem("access_token") ? "Bearer " + localStorage.getItem("access_token") : null,
                    "Content-Type": "application/json",
                    accept: "application/json"
                },
                updateAccessToken: true
            })
            return data
        }

        const configData = await fetchData()
        dispatch(
            configActions.updateConfig({
                data: !configData.error ? configData : [],
                loaded: true,
                error: configData.error || "",
                updated: false
            })
        )
        if (configData.error) {
            dispatch(
                alertActions.showNotification({
                    title: "Error",
                    body: (
                        <div className="alert alert-danger">
                            <p>{configData.error}</p>
                        </div>
                    )
                })
            )
        }
    }
}
export const fetchConfigDataByDemo = (sportId) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const data = await getData(`${process.env.REACT_APP_BACKEND_URL}/${sportId}/config/demo`, {
                headers: {
                    Authorization: localStorage.getItem("access_token") ? "Bearer " + localStorage.getItem("access_token") : null,
                    "Content-Type": "application/json",
                    accept: "application/json"
                },
                updateAccessToken: true
            })
            return data
        }

        const configData = await fetchData()
        dispatch(
            configActions.updateConfig({
                data: !configData.error ? configData : [],
                loaded: true,
                error: configData.error || "",
                updated: false
            })
        )
    }
}
export const fetchConfigDataByShowcase = (sportId, eventStatus) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const data = await getData(`${process.env.REACT_APP_BACKEND_URL}/${sportId}/config/${eventStatus}/showcase`, {
                headers: {
                    Authorization: localStorage.getItem("access_token") ? "Bearer " + localStorage.getItem("access_token") : null,
                    "Content-Type": "application/json",
                    accept: "application/json"
                },
                updateAccessToken: true
            })
            return data
        }

        const configData = await fetchData()
        dispatch(
            configActions.updateConfig({
                data: !configData.error ? configData : [],
                loaded: true,
                error: configData.error || "",
                updated: false
            })
        )
    }
}

export const updateConfigData = (sportId, eventStatus, configId, formData) => {
    return async (dispatch) => {
        const updateData = async () => {
            const data = await postData(`${process.env.REACT_APP_BACKEND_URL}/${sportId}/config/${configId}/update`, {
                headers: {
                    Authorization: localStorage.getItem("access_token") ? "Bearer " + localStorage.getItem("access_token") : null,
                    "Content-Type": "application/json",
                    accept: "application/json"
                },
                updateAccessToken: true,
                data: formData
            })
            return data
        }

        const configData = await updateData()
        dispatch(
            configActions.updateConfig({
                data: [],
                loaded: true,
                error: configData.error || "",
                updated: true
            })
        )
    }
}

export const configActions = configSlice.actions

export default configSlice
