import { createSlice } from "@reduxjs/toolkit"
import { getData } from "../utils/connection-utils"
import { alertActions } from "./alert-slice"

const eventsSlice = createSlice({
    name: "events",
    initialState: {
        data: [],
        loaded: false
    },
    reducers: {
        updateEvents(state, action) {
            state.data = action.payload.data || []
            state.loaded = action.payload.loaded
        },
        clearEvents(state) {
            state.data = []
            state.loaded = false
        }
    }
})

export const fetchEventsData = (sportId) => {
    return async (dispatch) => {
        const fetchData = async () => {
            let data = {}
            if (sportId) {
                data = await getData(`${process.env.REACT_APP_BACKEND_URL}/events/get/${sportId}`)
            }
            let eventsData = []
            data.map((d) => eventsData.push({ ...d.fields, id: d.pk }))
            return eventsData
        }

        const eventsData = await fetchData()
        dispatch(
            eventsActions.updateEvents({
                data: !eventsData.error ? eventsData : [],
                loaded: true,
                error: eventsData.error || ""
            })
        )
        if (eventsData.error) {
            dispatch(
                alertActions.showNotification({
                    title: "Error",
                    body: (
                        <div className="alert alert-danger">
                            <p>{eventsData.error}</p>
                        </div>
                    )
                })
            )
        }
    }
}
export const fetchEventsDataByStatus = (sportId, eventStatus) => {
    return async (dispatch) => {
        const fetchData = async () => {
            let data = {}
            if (sportId && eventStatus) {
                data = await getData(`${process.env.REACT_APP_BACKEND_URL}/events/get/${sportId}/${eventStatus}`)
            }
            let eventsData = []
            data.map((d) => eventsData.push({ ...d.fields }))
            return eventsData
        }

        const eventsData = await fetchData()
        dispatch(
            eventsActions.updateEvents({
                data: !eventsData.error ? eventsData : [],
                loaded: true,
                error: eventsData.error || ""
            })
        )
        if (eventsData.error) {
            dispatch(
                alertActions.showNotification({
                    title: "Error",
                    body: (
                        <div className="alert alert-danger">
                            <p>{eventsData.error}</p>
                        </div>
                    )
                })
            )
        }
    }
}

export const eventsActions = eventsSlice.actions

export default eventsSlice
