import { useEffect } from "react"
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { fetchSportsData } from "../../store/sports-slice"
import { NavDropdown } from "react-bootstrap"
import { getCurrentSportByURLShortcut } from "../../utils/connection-utils"
import { authActions } from "../../store/auth-slice"

export default function Navigation(props) {
    const dispatch = useDispatch()
    const auth = useSelector((state) => state.auth)
    const sports = useSelector((state) => state.sports)
    useEffect(() => {
        if (!sports.loaded) dispatch(fetchSportsData())
        if (!auth.loaded) dispatch(authActions.refreshAuthFromToken())
    }, [sports.loaded, auth.loaded, dispatch])

    const currentSport = getCurrentSportByURLShortcut(props.sportId, sports)

    return (
        <>
            <nav className="px-5 d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 border-bottom">
                <div className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none">
                    <Link to="/">
                        <img src={`${process.env.REACT_APP_LOCATION_S3}/core/images/logo.png`} width="40px" height="32px" alt="FansXR" />
                    </Link>
                    {currentSport ? <img src={currentSport.icon} width="40px" height="32px" alt={currentSport.url_shortcut} /> : null}
                </div>

                <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
                    <li>
                        <NavDropdown title="Events">
                            {sports.data.map((s, i) => {
                                const linkHref = `/${s.url_shortcut}/events`
                                return (
                                    <Link key={i} to={linkHref} className="dropdown-item">
                                        {s.name}
                                    </Link>
                                )
                            })}
                        </NavDropdown>
                    </li>
                    {auth.loggedIn ? (
                        <li>
                            <Link to="/profile" className="nav-link px-2 link-dark text-decoration-none">
                                Profile
                            </Link>
                        </li>
                    ) : null}
                    {auth.data && auth.data.isStaff ? (
                        <li>
                            <Link to="/admin" className="nav-link px-2 link-dark text-decoration-none">
                                Admin
                            </Link>
                        </li>
                    ) : null}
                </ul>

                <div className="col-md-3 text-end">
                    {auth.loggedIn ? (
                        <>
                            <Link to="/auth/logout" className="btn btn-primary me-2">
                                Logout
                            </Link>
                        </>
                    ) : (
                        <>
                            <Link to="/auth/login" className="btn btn-outline-primary me-2">
                                Login
                            </Link>
                            <Link to="/auth/signup" className="btn btn-primary me-2">
                                Sign-up
                            </Link>
                        </>
                    )}
                </div>
            </nav>
            {props.navExtra || null}
            {props.noMarginBottom || <div className="mb-4"></div>}
        </>
    )
}
