// import "aframe"
import { Entity } from "aframe-react"

export function BetBoxAssets(props) {
    return props.config.designBetBox ? <img alt="Bet Box" crossOrigin="anonymous" id="imgBetBox" src={props.config.designBetBox} /> : null
}

export default function BetBoxEntity(props) {
    return (
        <>
            <Entity
                data-desc="Bet Box Panel"
                primitive="a-plane"
                material={{ transparent: true, src: props.config.designBetBox ? "#imgBetBox" : "" }}
                position={{ z: -4.8 }}
                width="6"
                height="6"
            >
                <Entity
                    data-desc="Bet Box"
                    primitive="a-plane"
                    material={{ transparent: true }}
                    position={{ x: 2.68, y: 2.5, z: 0.1 }}
                    width="0.5"
                    height="0.5"
                    className="clickable"
                    events={{ click: props.handleHideBoxClicked }}
                />
            </Entity>
        </>
    )
}
