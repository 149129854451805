import { createSlice } from "@reduxjs/toolkit"
import { getData } from "../utils/connection-utils"
import { alertActions } from "./alert-slice"

const sportsSlice = createSlice({
    name: "sports",
    initialState: {
        data: [],
        loaded: false,
        error: ""
    },
    reducers: {
        updateSports(state, action) {
            state.data = action.payload.data
            state.loaded = action.payload.loaded
            state.error = action.payload.error
        }
    }
})

export const fetchSportsData = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            let sportsData = []
            const data = await getData(`${process.env.REACT_APP_BACKEND_URL}/sports/get`)
            data.map((d) => sportsData.push({ ...d.fields }))
            return sportsData
        }

        const sportsData = await fetchData()
        dispatch(
            sportsActions.updateSports({
                data: !sportsData.error ? sportsData : [],
                loaded: true,
                error: sportsData.error || ""
            })
        )
        if (sportsData.error) {
            dispatch(
                alertActions.showNotification({
                    title: "Error",
                    body: (
                        <div className="alert alert-danger">
                            <p>{sportsData.error}</p>
                        </div>
                    )
                })
            )
        }
    }
}

export const sportsActions = sportsSlice.actions

export default sportsSlice
