import Layout from "../../components/Layout/Layout"
import { useDispatch, useSelector } from "react-redux"
import { Link, Redirect, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { configActions, fetchConfigDataById, updateConfigData } from "../../store/config-slice"
import { Button, Form, Spinner } from "react-bootstrap"
import { EVENTSTATUS_LIVE } from "../../utils/constants"

export default function AdminTeamConfigPage() {
    const dispatch = useDispatch()
    const config = useSelector((state) => state.config)
    const [isSubmitting, setSubmitting] = useState(false)
    const [formValues, setFormValues] = useState({})
    const { sportId, eventStatus, configId } = useParams()
    useEffect(() => {
        dispatch(configActions.clearConfig())
        dispatch(fetchConfigDataById(sportId, eventStatus, configId))
        setFormValues({})
    }, [sportId, eventStatus, configId, dispatch])

    let teamConfigPage = (
        <div className="d-flex justify-content-center">
            <Spinner animation="border" />
        </div>
    )

    if (config.updated) {
        return <Redirect to={`/admin/${sportId}/${eventStatus}`} />
    }

    const handlerSubmitForm = () => {
        setSubmitting(true)
        dispatch(updateConfigData(sportId, eventStatus, configId, formValues))
    }

    const handleFormValuesOnChange = (e) => setFormValues({ ...formValues, [e.target.getAttribute("data-k")]: e.target.value })

    if (config.loaded && config.data.configs?.length > 0) {
        if (Object.keys(formValues).length === 0) {
            let tmpFormValues = {}
            Object.entries(config.data.configs[0].fields)
                // eslint-disable-next-line no-unused-vars
                .filter(([k, v]) => k !== "team" && k !== "type")
                .map(([k, v]) => (tmpFormValues[k] = v))
            setFormValues(tmpFormValues)
        } else {
            teamConfigPage = (
                <>
                    <div className="container bg-light">
                        <Form className="p-4">
                            {Object.entries(formValues).map(([k, v]) => {
                                let input = <input value={v} className="form-control" type="text" data-k={k} onChange={handleFormValuesOnChange} />
                                if (k === "showcase") {
                                    input = (
                                        <select className="form-select" defaultValue={v ? 1 : 0} data-k={k} onChange={handleFormValuesOnChange}>
                                            <option value="1">True</option>
                                            <option value="0">False</option>
                                        </select>
                                    )
                                }

                                return (
                                    <div className="form-group mb-2" key={k}>
                                        <label>{k}</label>
                                        {input}
                                    </div>
                                )
                            })}
                            <Button variant="success" onClick={handlerSubmitForm} disabled={isSubmitting}>
                                {isSubmitting ? <Spinner animation="border" style={{ width: "16px", height: "16px" }} /> : "Save"}
                            </Button>
                        </Form>
                    </div>
                </>
            )
        }
    }

    return (
        <Layout sportId={sportId}>
            <div className="container">
                <h3>
                    <Link to={`/admin/${sportId}/${eventStatus}`} className="text-decoration-none me-3">
                        &lt;
                    </Link>
                    {eventStatus === EVENTSTATUS_LIVE ? "Team Configs" : "VoDs"} - {sportId}
                </h3>
                {teamConfigPage}
            </div>
        </Layout>
    )
}
