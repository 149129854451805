import { createSlice } from "@reduxjs/toolkit"

const alertSlice = createSlice({
    name: "alert",
    initialState: { loaded: false, notification: null, alertType: "" },
    reducers: {
        showNotification(state, action) {
            state.loaded = true
            state.notification = {
                title: action.payload.title,
                body: action.payload.body
            }
            state.alertType = action.payload.alertType
        },
        hideNotification(state) {
            state.notification = null
        }
    }
})

export const alertActions = alertSlice.actions

export default alertSlice
