export const postData = async (url = "", options = {}) => {
    let headers = options.headers || {
        "Content-Type": "application/json",
        accept: "application/json"
    }
    let updateAccessToken = options.updateAccessToken || false
    // Default options are marked with *
    const fetchData = {
        url: url,
        requestInfo: {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: headers,
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: options.data ? JSON.stringify(options.data) : null
        }
    }
    // console.log("fetchData: ", fetchData)
    let response = await fetch(url, fetchData.requestInfo)
    if (updateAccessToken) response = await checkToken(fetchData, response)
    if (response.ok && response.status >= 200 && response.status < 300) return response.json()
    else return { error: true, response: response }
}

export const getData = async (url = "", options = {}) => {
    let headers = options.headers || {
        "Content-Type": "application/json",
        accept: "application/json"
    }
    let updateAccessToken = options.updateAccessToken || false
    // Default options are marked with *
    const fetchData = {
        url: url,
        requestInfo: {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: headers,
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: options.data ? JSON.stringify(options.data) : null
        }
    }
    let response = await fetch(url, fetchData.requestInfo)
    if (updateAccessToken) response = await checkToken(fetchData, response)
    if (response.ok && response.status >= 200 && response.status < 300) return response.json()
    else return { error: true, response: response }
}

export const getCurrentSportByURLShortcut = (urlShortcut, sports) => {
    return sports?.data?.filter((s) => s.url_shortcut === urlShortcut)[0]
}

export const checkTokenExpired = (token) => {
    if (token && token !== "undefined") {
        const tokenParts = JSON.parse(window.atob(token.split(".")[1]))
        return tokenParts.exp <= Math.ceil(Date.now() / 1000)
    }
    return true
}

export const obtainAuthTokenByLogin = async (username, password) => {
    const data = await postData(`${process.env.REACT_APP_BACKEND_URL}/auth/token/obtain`, {
        headers: {
            Authorization: null,
            "Content-Type": "application/json",
            accept: "application/json"
        },
        data: {
            username: username,
            password: password
        }
    })
    if (!data.error) {
        localStorage.setItem("access_token", data.access)
        localStorage.setItem("refresh_token", data.refresh)
    }
    return data
}

export const logoutAndBlacklist = async () => {
    const result = await postData(`${process.env.REACT_APP_BACKEND_URL}/auth/blacklist`, {
        data: {
            refresh_token: localStorage.getItem("refresh_token")
        }
    })
    localStorage.removeItem("access_token")
    localStorage.removeItem("refresh_token")
    return result
}

export const checkToken = async (fetchData, response) => {
    const accessTokenExpired = checkTokenExpired(localStorage.getItem("access_token"))
    const refreshTokenExpired = checkTokenExpired(localStorage.getItem("refresh_token"))
    if (accessTokenExpired) {
        if (!refreshTokenExpired) {
            const refreshResponse = await postData(`${process.env.REACT_APP_BACKEND_URL}/auth/token/refresh`, { data: { refresh: localStorage.getItem("refresh_token") } })
            if (!refreshResponse.error) {
                localStorage.setItem("access_token", refreshResponse.access)
                fetchData.requestInfo.headers.Authorization = `Bearer ${refreshResponse.access}`
                response = await fetch(fetchData.url, fetchData.requestInfo)
            }
        } else {
            console.log("Refresh token is expired/not available")
            response.tokenExpired = true
        }
    }
    return response
}

export const requestFullscreen = (entityRef) => {
    if (entityRef.current.el.requestFullScreen) {
        entityRef.current.el.requestFullScreen()
    } else if (entityRef.current.el.mozRequestFullScreen) {
        entityRef.current.el.mozRequestFullScreen()
    } else if (entityRef.current.el.webkitRequestFullScreen) {
        entityRef.current.el.webkitRequestFullScreen()
    }
}

export const parseJwt = (token) => {
    var base64Url = token.split(".")[1] || token
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
    var jsonPayload = decodeURIComponent(
        Buffer.from(base64, "base64")
            .toString()
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join("")
    )
    return JSON.parse(jsonPayload)
}
