import { Entity } from "aframe-react"

export function SliderAssets() {
    return (
        <>
            <img alt="Play button" id="imgSliderPlay" crossOrigin="anonymous" src={process.env.REACT_APP_LOCATION_S3 + "/vods/slider_controls/play.png"} />
            <img alt="Pause button" id="imgSliderPause" crossOrigin="anonymous" src={process.env.REACT_APP_LOCATION_S3 + "/vods/slider_controls/pause.png"} />
            <img alt="Double Arrow button" id="imgSliderDoubleArrow" crossOrigin="anonymous" src={process.env.REACT_APP_LOCATION_S3 + "/vods/slider_controls/double-arrow.png"} />
            <img alt="Rotate button" id="imgSliderRotate" crossOrigin="anonymous" src={process.env.REACT_APP_LOCATION_S3 + "/vods/slider_controls/rotate.png"} />
            <img alt="Loading" id="imgLoading" crossOrigin="anonymous" src={process.env.REACT_APP_LOCATION_S3 + "/vods/slider_controls/loading.png"} />
        </>
    )
}

export default function SliderEntity(props) {
    return (
        <>
            <Entity rotation="0 0 0" position="0 3.5 -5">
                <Entity
                    className="clickable"
                    primitive="a-plane"
                    height="0.5"
                    width="0.5"
                    position={{ x: -3.8 }}
                    material={{ transparent: true, src: props.pauseVideo ? "#imgSliderPlay" : "#imgSliderPause" }}
                    events={{ click: props.handlePlayPausedClicked }}
                ></Entity>
                <Entity
                    className="clickable"
                    primitive="a-plane"
                    height="0.5"
                    width="0.5"
                    position={{ x: -3 }}
                    material={{ transparent: true, src: "#imgSliderRotate" }}
                    events={{ click: props.handleResetClicked }}
                ></Entity>
                <Entity className="clickable" primitive="a-plane" height="0.2" width={props.wrapperWidth} color="gray" events={{ click: props.handleOnWrapperClicked }}></Entity>
                <Entity primitive="a-plane" height="0.2" width={props.bufferWidth} position={props.bufferPosition} color="#fff"></Entity>
                <Entity primitive="a-plane" height="0.2" width={props.progressWidth} position={props.progressPosition} color="#f55"></Entity>
                <Entity primitive="a-plane" height="0.2" width={props.progressTipWidth} position={props.progressTipPosition} color="#fa0"></Entity>
                <Entity
                    className="clickable"
                    primitive="a-plane"
                    height="0.5"
                    width="0.5"
                    position={{ x: 3 }}
                    rotation={{ z: 180 }}
                    material={{ transparent: true, src: "#imgSliderDoubleArrow" }}
                    events={{ click: props.handleRewindClicked }}
                ></Entity>
                <Entity
                    className="clickable"
                    primitive="a-plane"
                    height="0.5"
                    width="0.5"
                    position={{ x: 3.8 }}
                    material={{ transparent: true, src: "#imgSliderDoubleArrow" }}
                    events={{ click: props.handleForwardClicked }}
                ></Entity>
                {props.showBufferingMsg && (
                    <Entity primitive="a-plane" height="0.5" width="0.5" position={{ y: -0.5 }} material={{ transparent: true, src: "#imgLoading" }}></Entity>
                )}
            </Entity>
        </>
    )
}
