import { createSlice } from "@reduxjs/toolkit"
import { getData } from "../utils/connection-utils"
import { alertActions } from "./alert-slice"

const playersApiSlice = createSlice({
    name: "playersApi",
    initialState: {
        data: [],
        loaded: false,
        error: ""
    },
    reducers: {
        updatePlayersApi(state, action) {
            state.data = action.payload.data
            state.loaded = action.payload.loaded
            state.error = action.payload.error
        }
    }
})

export const fetchPlayersApiData = (sportId) => {
    return async (dispatch) => {
        const fetchData = async () => {
            return await getData(`${process.env.REACT_APP_BACKEND_URL}/${sportId}/config/players`)
        }

        const playersApiData = await fetchData()
        dispatch(
            playersApiActions.updatePlayersApi({
                data: !playersApiData.error ? playersApiData : [],
                loaded: true,
                error: playersApiData.error || ""
            })
        )
        if (playersApiData.error) {
            dispatch(
                alertActions.showNotification({
                    title: "Error",
                    body: (
                        <div className="alert alert-danger">
                            <p>{playersApiData.error}</p>
                        </div>
                    )
                })
            )
        }
    }
}

export const playersApiActions = playersApiSlice.actions

export default playersApiSlice
