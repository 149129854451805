import Navbar from "./../Navbar/Navbar"

export default function Layout(props) {
    return (
        <>
            {!props.landingPage && <Navbar navExtra={props.navExtra} sportId={props.sportId} noMarginBottom={props.noMarginBottom} />}
            <main>{props.children}</main>
        </>
    )
}
