import { configureStore } from "@reduxjs/toolkit"
import alertSlice from "./alert-slice"
import authSlice from "./auth-slice"
import sportsSlice from "./sports-slice"
import teamsSlice from "./teams-slice"
import eventsSlice from "./events-slice"
import eventSubscribersSlice from "./event-subscribers-slice"
import profileSlice from "./profile-slice"
import configSlice from "./config-slice"
import playersApiSlice from "./players-api-slice"

const createStore = configureStore({
    reducer: {
        alert: alertSlice.reducer,
        auth: authSlice.reducer,
        sports: sportsSlice.reducer,
        teams: teamsSlice.reducer,
        events: eventsSlice.reducer,
        eventSubscribers: eventSubscribersSlice.reducer,
        profile: profileSlice.reducer,
        config: configSlice.reducer,
        playersApi: playersApiSlice.reducer
    }
})

export default createStore
