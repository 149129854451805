// import "aframe"
import { Entity } from "aframe-react"
import { useState } from "react"
import SportEntity from "./SportEntity"

export function SportBoxAssets(props) {
    return (
        <>
            {props.config.designSportsPanel ? <img alt="Sports panel" crossOrigin="anonymous" id="imgSportsPanel" src={props.config.designSportsPanel} /> : null}
            {props.config.designSportsPopup ? <img alt="Sports popup panel" crossOrigin="anonymous" id="imgSportsPopupPanel" src={props.config.designSportsPopup} /> : null}
        </>
    )
}

export default function SportBoxEntity(props) {
    const [showDropdown, setShowDropdown] = useState(false)
    const [currentList, setCurrentList] = useState([0, 1, 2, 3, 4, 5])
    const sports = props.sports.data

    const handleShowHideDropdown = () => {
        setShowDropdown(!showDropdown)
    }

    const handleSportClicked = (e) => {
        console.log(e.target)
        // return <Redirect to={`/${e.target.getAttribute("data-sportid")}/live/1`} />
    }

    const handleSportsUpClicked = () => {
        if (currentList[0] > 0) setCurrentList(currentList.map((pId) => pId - 1))
    }
    const handleSportsDownClicked = () => {
        if (currentList[5] < Object.keys(sports).length - 1) setCurrentList(currentList.map((pId) => pId + 1))
    }

    return (
        <>
            <Entity
                data-desc="Sports Panel"
                primitive="a-plane"
                material={{
                    transparent: true,
                    src: props.config.designSportsPanel ? "#imgSportsPanel" : ""
                }}
                position={{ x: -1.9 }}
                width="4.5"
                className="clickable"
                events={{ click: handleShowHideDropdown }}
            >
                <Entity primitive="a-text" text={{ value: "Choose your sport" }} position={{ x: -2, y: -0.07, z: 0.1 }} scale={{ x: 1.5, y: 1.5, z: 1.5 }} />
            </Entity>
            {showDropdown ? (
                <Entity
                    data-desc="Sports Panel Popup"
                    primitive="a-plane"
                    material={{
                        transparent: true,
                        src: props.config.designSportsPopup ? "#imgSportsPopupPanel" : ""
                    }}
                    position={{ x: -1.9, y: -3.2, z: 0.2 }}
                    width="3"
                    height="4"
                    scale={{ x: 1.3, y: 1.3, z: 1.3 }}
                >
                    <SportEntity position={{ x: -0.1, y: 1.75, z: 0.1 }} height={0.3} events={{ click: handleSportsUpClicked }} arrowDirection="up" />
                    <SportEntity
                        config={props.config}
                        sportId={sports[currentList[0]]?.url_shortcut || ""}
                        text={sports[currentList[0]]?.name || ""}
                        position={{ x: -0.1, y: 1.25, z: 0.1 }}
                        events={{ click: handleSportClicked }}
                    />
                    <SportEntity
                        config={props.config}
                        sportId={sports[currentList[1]]?.url_shortcut || ""}
                        text={sports[currentList[1]]?.name || ""}
                        position={{ x: -0.1, y: 0.75, z: 0.1 }}
                        events={{ click: handleSportClicked }}
                    />
                    <SportEntity
                        config={props.config}
                        sportId={sports[currentList[2]]?.url_shortcut || ""}
                        text={sports[currentList[2]]?.name || ""}
                        position={{ x: -0.1, y: 0.25, z: 0.1 }}
                        events={{ click: handleSportClicked }}
                    />
                    <SportEntity
                        config={props.config}
                        sportId={sports[currentList[3]]?.url_shortcut || ""}
                        text={sports[currentList[3]]?.name || ""}
                        position={{ x: -0.1, y: -0.25, z: 0.1 }}
                        events={{ click: handleSportClicked }}
                    />
                    <SportEntity
                        config={props.config}
                        sportId={sports[currentList[4]]?.url_shortcut || ""}
                        text={sports[currentList[4]]?.name || ""}
                        position={{ x: -0.1, y: -0.75, z: 0.1 }}
                        events={{ click: handleSportClicked }}
                    />
                    <SportEntity
                        config={props.config}
                        sportId={sports[currentList[5]]?.url_shortcut || ""}
                        text={sports[currentList[5]]?.name || ""}
                        position={{ x: -0.1, y: -1.25, z: 0.1 }}
                        events={{ click: handleSportClicked }}
                    />
                    <SportEntity position={{ x: -0.1, y: -1.75, z: 0.1 }} events={{ click: handleSportsDownClicked }} arrowDirection="down" />
                </Entity>
            ) : null}
        </>
    )
}
