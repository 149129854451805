// import "aframe"
import { Entity } from "aframe-react"

export function ArcsAssets(props) {
    return (
        <>
            {props.config.designArcLeft ? <img alt="Arc Left" id="imgArcLeft" crossOrigin="anonymous" src={props.config.designArcLeft} /> : null}
            {props.config.designArcRight ? <img alt="Arc Right" id="imgArcRight" crossOrigin="anonymous" src={props.config.designArcRight} /> : null}
        </>
    )
}

export default function ArcsEntity(props) {
    return (
        <>
            <Entity
                data-desc="Arc Left"
                primitive="a-plane"
                material={{
                    transparent: true,
                    src: props.config.designArcLeft ? "#imgArcLeft" : ""
                }}
                position={{ z: props.z || -5.1, x: (props.x || -7.8) - (props.adjust || 0), y: props.y || 0 }}
                height={props.height || "7"}
                width={props.width || "1"}
            />
            <Entity
                data-desc="Arc Right"
                primitive="a-plane"
                material={{
                    transparent: true,
                    src: props.config.designArcRight ? "#imgArcRight" : ""
                }}
                position={{ z: props.z || -5.1, x: (props.x || 7.8) - (props.adjust || 0), y: -props.y || 0 }}
                height={props.height || "7"}
                width={props.width || "1"}
            />
        </>
    )
}
