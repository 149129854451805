import { useState } from "react"
import { useEffect } from "react"
import { Spinner } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import Layout from "../../components/Layout/Layout"
import { emailEventSubscriber, fetchEventSubscribersData } from "../../store/event-subscribers-slice"

export default function AdminTeamConfigPage() {
    const dispatch = useDispatch()
    const [isSubmitting, setSubmitting] = useState(false)
    const [currentEvent, setCurrentEvent] = useState("")
    const eventSubscribers = useSelector((state) => state.eventSubscribers)

    useEffect(() => {
        dispatch(fetchEventSubscribersData())
    }, [eventSubscribers.loaded, dispatch])

    const handlerEmailSubmitForm = async (e, eventSubscriberId) => {
        setSubmitting(true)
        await dispatch(emailEventSubscriber(eventSubscriberId))
        setSubmitting(false)
    }

    const handleCurrentEventChange = (e) => {
        setCurrentEvent(e.target.value)
    }

    let page = (
        <div className="d-flex justify-content-center">
            <Spinner animation="border" />
        </div>
    )

    if (eventSubscribers.loaded && eventSubscribers.data.length > 0) {
        const events = [...new Set(eventSubscribers.data.map((d) => d.event))]
        page = (
            <>
                <select className="form-select mt-5 event-selector" onChange={(e) => handleCurrentEventChange(e)}>
                    <option value="">Filter for an event</option>
                    {events.map((e, i) => (
                        <option key={i}>{e}</option>
                    ))}
                </select>
                <table className="table table-bordered table-hover mt-3">
                    <colgroup>
                        <col style={{ width: "50%" }} />
                        <col style={{ width: "50%" }} />
                        <col style={{ width: "200px" }} />
                    </colgroup>
                    <thead>
                        <tr className="bg-dark">
                            <th className="text-white">User</th>
                            <th className="text-white">Event</th>
                            <th className="text-white"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {eventSubscribers.data
                            .filter((d) => (currentEvent !== "" ? d.event === currentEvent : true))
                            .map((d, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="td-username">{d.user}</td>
                                        <td className="td-event-name">{d.event}</td>
                                        <td>
                                            <button onClick={(e) => handlerEmailSubmitForm(e, d.id)} disabled={isSubmitting} className="btn btn-warning">
                                                {isSubmitting ? <Spinner animation="border" style={{ width: "16px", height: "16px" }} /> : "Email"}
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </>
        )
    }

    return (
        <Layout>
            <div className="container">
                <h3>
                    <Link to={"/admin"} className="text-decoration-none me-3">
                        &lt;
                    </Link>
                    Event Subscribers
                </h3>
                {page}
            </div>
        </Layout>
    )
}
