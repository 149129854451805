// import "aframe"
import { Entity, Scene } from "aframe-react"
import { useEffect, useRef, useState } from "react"
import ReactHlsPlayer from "react-hls-player"
import { requestFullscreen } from "../../../utils/connection-utils"
import { STREAM_FORMAT_HLS, STREAM_FORMAT_MP4, STREAM_FORMAT_WEBRTC } from "../../../utils/constants"
import { initializeWebRTCPlayer } from "../../../utils/webrtc"
import ArcsEntity, { ArcsAssets } from "../ArcsEntity"
import MapEntity, { MapAssets } from "../MapEntity"
import PiPBoxEntity, { PiPBoxAssets } from "../PiPBoxEntity"
import { useDispatch, useSelector } from "react-redux"
import { fetchProfileData } from "../../../store/profile-slice"
import { addToAnalytics } from "../../../store/analytics-slice"

export default function AScene(props) {
    const dispatch = useDispatch()
    const profile = useSelector((state) => state.profile)
    const [startVideo, setStartVideo] = useState(false)
    const [currentStreamData, setCurrentStreamData] = useState({
        cam: "0",
        url: "",
        updateStream: false
    })
    const [currentPip1, setCurrentPip1] = useState("")
    const [currentPip2, setCurrentPip2] = useState("")
    const previousStreamURL = useRef(currentStreamData.url)
    const previousPip1 = useRef(currentPip1)
    const previousPip2 = useRef(currentPip2)
    const [webRTCAdaptorStream, setWebRTCAdaptorStream] = useState(null)
    const [webRTCAdaptorPip1, setWebRTCAdaptorPip1] = useState(null)
    const [webRTCAdaptorPip2, setWebRTCAdaptorPip2] = useState(null)

    let analyticsData = {}
    let cameraRef = useRef()
    let streamRef = useRef()
    let pip1Ref = useRef()
    let pip2Ref = useRef()
    const sceneRef = useRef()
    // eslint-disable-next-line no-unused-vars
    let intervalAnalytics = null
    let startingPosition = props.config.startingPosition ? props.config.startingPosition : "0 0 0"
    let startingRotation = props.config.startingRotation ? props.config.startingRotation : "0 0 0"

    const updateStreamByCam = (currentCamId) => {
        setCurrentStreamData({
            cam: currentCamId,
            url: props.config[`video${parseInt(currentCamId) + 1}`] || props.config.video1
        })
    }

    useEffect(() => {
        if (!profile.loaded) dispatch(fetchProfileData())
        if (startVideo) {
            if (previousStreamURL.current !== currentStreamData.url) {
                if (streamRef.current) {
                    if (props.streamFormat === STREAM_FORMAT_WEBRTC) {
                        webRTCAdaptorStream.play(currentStreamData.url, "")
                    } else {
                        streamRef.current.play()
                    }
                }
                previousStreamURL.current = currentStreamData.url
            }
            if (previousPip1.current !== currentPip1) {
                if (pip1Ref.current) {
                    if (props.streamFormat === STREAM_FORMAT_WEBRTC) {
                        webRTCAdaptorPip1.play(currentPip1, "")
                    } else {
                        pip1Ref.current.play()
                    }
                }
                previousPip1.current = currentPip1
            }
            if (previousPip2.current !== currentPip2) {
                if (pip2Ref.current) {
                    if (props.streamFormat === STREAM_FORMAT_WEBRTC) {
                        webRTCAdaptorPip2.play(currentPip2, "")
                    } else {
                        pip2Ref.current.play()
                    }
                }
                previousPip2.current = currentPip2
            }
        }
    }, [profile.loaded, startVideo, currentStreamData.url, currentPip1, currentPip2, webRTCAdaptorPip1, webRTCAdaptorPip2, webRTCAdaptorStream, props.streamFormat])
    useEffect(() => {
        if (props.streamFormat === STREAM_FORMAT_WEBRTC) {
            setWebRTCAdaptorStream(initializeWebRTCPlayer(currentStreamData.url, null, streamRef))
            setWebRTCAdaptorPip1(initializeWebRTCPlayer(currentPip1, null, pip1Ref))
            setWebRTCAdaptorPip2(initializeWebRTCPlayer(currentPip2, null, pip2Ref))
        }
    }, [currentStreamData.url, currentPip1, currentPip2, props.streamFormat])

    const handlePlayOnClick = () => {
        setStartVideo(true)
        requestFullscreen(sceneRef)

        if (props.analyticsData.enabled) {
            intervalAnalytics = setInterval(() => {
                analyticsData = {
                    sportId: props.analyticsData.sportId,
                    configId: props.analyticsData.configId,
                    teamId: props.analyticsData.teamId,
                    payload: {
                        position: cameraRef.current.el.object3D.position,
                        rotation: cameraRef.current.el.object3D.rotation,
                        scale: cameraRef.current.el.object3D.scale
                    }
                }
                dispatch(addToAnalytics(analyticsData))
            }, 5000)
        }
    }

    const handleCamClicked = (e) => updateStreamByCam(e.target.getAttribute("data-cam-id"))
    const handleBetButtonClicked = () => window.open(props.config.betLink || "https://fansxr.com", "_blank").focus()

    if (props.config[`video${parseInt(currentStreamData.cam) + 1}`] !== currentStreamData.url) updateStreamByCam(currentStreamData.cam)
    if (props.config.pip1 !== currentPip1) setCurrentPip1(props.config.pip1)
    if (props.config.pip2 !== currentPip2) setCurrentPip2(props.config.pip2)

    return (
        <Scene ref={sceneRef} vr-mode-ui="enabled: false">
            <a-assets>
                <img alt="Play button" id="imgPlay" crossOrigin="anonymous" src={process.env.REACT_APP_LOCATION_S3 + "/core/images/play.png"} />
                <ArcsAssets {...props} />
                <MapAssets {...props} />
                <PiPBoxAssets {...props} pip1Ref={pip1Ref} pip2Ref={pip2Ref} />
                {props.config.designBet ? <img alt="Bet Button" id="imgBetButton" crossOrigin="anonymous" src={props.config.designBet} /> : null}
                {props.streamFormat === STREAM_FORMAT_HLS && (
                    <ReactHlsPlayer playerRef={streamRef} id="vidStream" crossOrigin="anonymous" src={currentStreamData.url} controls muted></ReactHlsPlayer>
                )}
                {props.streamFormat === STREAM_FORMAT_MP4 && <video ref={streamRef} id="vidStream" crossOrigin="anonymous" src={currentStreamData.url} controls muted />}
                <img alt="Transparent Image" crossOrigin="anonymous" id="imgTransparent" src={`${process.env.REACT_APP_LOCATION_S3}/core/images/transparent.png`} />
            </a-assets>
            {props.streamFormat === STREAM_FORMAT_WEBRTC && <video ref={streamRef} id="vidStream" crossOrigin="anonymous" src={currentStreamData.url} muted autoPlay={true} />}
            {props.streamFormat === STREAM_FORMAT_WEBRTC && <video ref={pip1Ref} id="vidPip1" crossOrigin="anonymous" src={props.config.pip1} autoPlay={true}></video>}
            {props.streamFormat === STREAM_FORMAT_WEBRTC && <video ref={pip2Ref} id="vidPip2" crossOrigin="anonymous" src={props.config.pip2} autoPlay={true} muted></video>}
            <Entity position={startingPosition} rotation={startingRotation}>
                <Entity primitive="a-camera" position={{ y: 1.6 }} raycaster={{ objects: [".clickable"] }} cursor={{ rayOrigin: "mouse", fuse: false }} ref={cameraRef}>
                    {!startVideo ? (
                        <Entity
                            primitive="a-plane"
                            position={{ z: -4.9 }}
                            material={{ transparent: true, src: "#imgPlay" }}
                            className="clickable"
                            events={{ click: handlePlayOnClick }}
                        />
                    ) : null}
                    {/* visual entities */}
                    <ArcsEntity {...props} />
                    {startVideo && (
                        <>
                            <MapEntity {...props} currentCam={currentStreamData.cam} handleCamClicked={handleCamClicked} position={{ y: 3, z: -5 }} />
                            <PiPBoxEntity {...props} position={{ y: -3, z: -5 }} />
                            <Entity
                                primitive="a-plane"
                                material={{
                                    transparent: true,
                                    src: props.config.designBet ? "#imgBetButton" : ""
                                }}
                                position={props.config.locationBet}
                                height="0.55"
                                width="1"
                                className="clickable"
                                events={{ click: handleBetButtonClicked }}
                            />
                        </>
                    )}
                </Entity>
            </Entity>
            <Entity primitive="a-sky" material={{ color: "black" }} />
            {startVideo ? <Entity primitive="a-videosphere" src="#vidStream" /> : null}
        </Scene>
    )
}
