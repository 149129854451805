import { useEffect } from "react"
import { Redirect } from "react-router-dom"
import { Spinner } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { authLogout } from "../../store/auth-slice"
import { profileActions } from "../../store/profile-slice"

export default function Login() {
    const dispatch = useDispatch()
    const auth = useSelector((state) => state.auth)

    useEffect(() => {
        dispatch(authLogout())
        dispatch(profileActions.clearProfile())
    }, [dispatch])

    if (auth.loaded && !auth.loggedIn) return <Redirect to="/" />
    else
        return (
            <div className="d-flex justify-content-center mt-5">
                <Spinner animation="border" />
            </div>
        )
}
