// import "aframe"
import { Entity } from "aframe-react"

export function LogoFXRAssets(props) {
    return props.config.designLogoFXR ? <img alt="FXR Logo" crossOrigin="anonymous" id="imgLogoFXR" src={props.config.designLogoFXR} /> : null
}

export default function LogoFXREntity(props) {
    return (
        <Entity
            data-desc="FXR Logo"
            primitive="a-plane"
            material={{ transparent: true, src: props.config.designLogoFXR ? "#imgLogoFXR" : "" }}
            position={props.position || { x: -1 }}
            className="clickable"
            events={props.events}
        />
    )
}
