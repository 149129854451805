import { Link, useParams } from "react-router-dom"
import Layout from "../../components/Layout/Layout"

export default function Landing() {
    const { sportId } = useParams()

    return (
        <Layout>
            <div className="container mt-5 pt-5" style={{ maxWidth: "600px" }}>
                <div className="row d-flex justify-content-center">
                    <Link to={`/${sportId}/ar/view`} className="p-5 btn btn-outline-dark" style={{ width: "80vw", fontSize: "40px" }}>
                        <strong>GO</strong>
                    </Link>
                </div>
                <div className="row border-top mt-5">
                    <Link to={`/${sportId}/ar/ticketing`} className="p-4 text-decoration-none text-dark text-center">
                        Ticketing
                    </Link>
                </div>
                <div className="row border-top">
                    <Link to={`/${sportId}/ar/wayfinding`} className="p-4 text-decoration-none text-dark text-center">
                        Wayfinding
                    </Link>
                </div>
                <div className="row border-top">
                    <Link to={`/${sportId}/ar/rules`} className="p-4 text-decoration-none text-dark text-center">
                        Rules
                    </Link>
                </div>
                <div className="row border-top">
                    <Link to={"/"} className="p-4 text-decoration-none text-dark text-center">
                        Weblink
                    </Link>
                </div>
            </div>
        </Layout>
    )
}
