// import "aframe"
import { Entity } from "aframe-react"

export function LogoXRAssets(props) {
    return props.config.designLogoXR ? <img alt="XR Logo" id="imgLogoXR" crossOrigin="anonymous" src={props.config.designLogoXR} /> : null
}

export default function LogoXREntity(props) {
    return (
        <Entity
            data-desc="XR Logo"
            primitive="a-plane"
            material={{ transparent: true, src: props.config.designLogoXR ? "#imgLogoXR" : "" }}
            position={props.position || { x: 1 }}
            width={props.width || 1}
        />
    )
}
