// import "aframe"
import { Entity } from "aframe-react"
import { useState } from "react"
import ReactHlsPlayer from "react-hls-player/dist"
import { STREAM_FORMAT_HLS, STREAM_FORMAT_MP4 } from "../../utils/constants"

export function PiPBoxAssets(props) {
    return (
        <>
            {props.config.pip1 && props.streamFormat === STREAM_FORMAT_HLS && (
                <ReactHlsPlayer data-desc="PiP 1 HLS" playerRef={props.pip1Ref} id="vidPip1" crossOrigin="anonymous" src={props.config.pip1} controls></ReactHlsPlayer>
            )}
            {props.config.pip1 && props.streamFormat === STREAM_FORMAT_MP4 && (
                <video data-desc="PiP 1 MP4" ref={props.pip1Ref} id="vidPip1" crossOrigin="anonymous" src={props.config.pip1} controls></video>
            )}
            {props.config.pip2 && props.streamFormat === STREAM_FORMAT_HLS && (
                <ReactHlsPlayer data-desc="PiP 2 HLS" playerRef={props.pip2Ref} id="vidPip2" crossOrigin="anonymous" src={props.config.pip2} controls muted></ReactHlsPlayer>
            )}
            {props.config.pip2 && props.streamFormat === STREAM_FORMAT_MP4 && (
                <video data-desc="PiP 2 MP4" ref={props.pip2Ref} id="vidPip2" crossOrigin="anonymous" src={props.config.pip2} controls muted></video>
            )}{" "}
            {props.config.ad ? <img alt="Ad" id="imgAd" crossOrigin="anonymous" src={props.config.ad} /> : null}
            {props.config.designPiPBox ? <img alt="PiP Box" id="imgPipBox" crossOrigin="anonymous" src={props.config.designPiPBox} /> : null}
        </>
    )
}

export default function PiPBoxEntity(props) {
    const [maximizePip1, setMaximizePip1] = useState(false)
    const [maximizePip2, setMaximizePip2] = useState(false)

    const handleFullscreenPIP1 = () => {
        setMaximizePip1(!maximizePip1)
    }
    const handleFullscreenPIP2 = () => {
        setMaximizePip2(!maximizePip2)
    }

    return (
        <Entity
            data-desc="PiPBox Panel"
            primitive="a-plane"
            material={{ transparent: true, src: props.config.designBet ? "#imgPipBox" : "" }}
            position={props.position}
            width="4.3"
            height="2.1"
        >
            <Entity
                data-desc="PiP 1 Video"
                primitive="a-video"
                position={maximizePip1 ? { y: 3, z: 0.5 } : { x: -1.05, y: 0.3, z: 0.1 }}
                scale={maximizePip1 ? { x: 6.5, y: 4.5, z: 4.5 } : { x: 1, y: 1, z: 1 }}
                width="2"
                height="1.5"
                src={props.config.pip1 ? "#vidPip1" : ""}
                className="clickable"
                events={{ click: handleFullscreenPIP1 }}
            />
            <Entity
                data-desc="PiP 2 Video"
                primitive="a-video"
                position={maximizePip2 ? { y: 3, z: 0.5 } : { x: 1.05, y: 0.3, z: 0.1 }}
                scale={maximizePip2 ? { x: 6.5, y: 4.5, z: 4.5 } : { x: 1, y: 1, z: 1 }}
                width="2"
                height="1.5"
                src={props.config.pip2 ? "#vidPip2" : ""}
                className="clickable"
                events={{ click: handleFullscreenPIP2 }}
            />
            {!props.withoutAd && (
                <Entity
                    data-desc="Ad Panel"
                    primitive="a-plane"
                    material={{ transparent: true, src: props.config.ad ? "#imgAd" : "" }}
                    position={{ y: -0.7, z: 0.1 }}
                    height="0.4"
                    width="4.1"
                />
            )}
        </Entity>
    )
}
