import { WebRTCAdaptor } from "./antmedia/webrtc_adaptor"

export const initializeWebRTCPlayer = (streamDataUrl, token, streamRef) => {
    let websocketURL = "wss://antmedia-cloud.fxr360.com/WebRTCAppEE/websocket"
    // let name = streamDataUrl
    let tmpStreamDataUrlArr = streamDataUrl.split(" ")
    if (tmpStreamDataUrlArr.length === 2) {
        websocketURL = tmpStreamDataUrlArr[0]
        // name = tmpStreamDataUrlArr[1]
    }

    streamRef.current.style.display = "block"
    return new WebRTCAdaptor({
        websocket_url: websocketURL,
        mediaConstraints: {
            video: false,
            audio: false
        },
        peerconnection_config: null,
        sdp_constraints: {
            OfferToReceiveAudio: true,
            OfferToReceiveVideo: true
        },
        remoteVideoId: streamRef.current.id,
        isPlayMode: true,
        debug: true,
        callback: function (info, description) {
            if (info === "initialized") {
                console.log("initialized")
                // if (webrtcAdaptor) webrtcAdaptor.getStreamInfo(name)
            } else if (info === "streamInformation") {
                console.log("stream information")
            } else if (info === "play_started") {
                console.log("play started")
            } else if (info === "play_finished") {
                console.log("play finished")
                //check that publish may start again
                setTimeout(function () {
                    // if (webrtcAdaptor) webrtcAdaptor.getStreamInfo(name)
                }, 3000)
            } else if (info === "closed") {
                //console.log("Connection closed")
                if (typeof description != "undefined") {
                    console.log("Connecton closed: " + JSON.stringify(description))
                }
            }
        },
        callbackError: function (error) {
            //some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError

            console.log("error callback: " + JSON.stringify(error))
        }
    })
}
