import { createSlice } from "@reduxjs/toolkit"
import { checkTokenExpired, getData, logoutAndBlacklist, obtainAuthTokenByLogin, postData } from "../utils/connection-utils"
import { alertActions } from "./alert-slice"

const authSlice = createSlice({
    name: "auth",
    initialState: {
        loaded: false,
        error: "",
        data: {},
        status: "",
        statusText: "",
        loggedIn: false,
        isStaff: false
    },
    reducers: {
        updateAuth(state, action) {
            state.loaded = action.payload.loaded
            state.data = action.payload.data
            state.error = action.payload.error
            state.status = action.payload.status
            state.statusText = action.payload.statusText
            state.loggedIn = action.payload.loggedIn
            state.isStaff = action.payload.isStaff
        },
        refreshAuthFromToken(state) {
            let reset = false
            if (!checkTokenExpired(localStorage.getItem("access_token"))) {
                console.log("access_token not expired")
                state.data = JSON.parse(window.atob(localStorage.getItem("access_token").split(".")[1]))
                state.loggedIn = true
            } else if (!checkTokenExpired(localStorage.getItem("refresh_token"))) {
                console.log("refresh_token not expired")
                state.data = JSON.parse(window.atob(localStorage.getItem("refresh_token").split(".")[1]))
                state.loggedIn = true
            } else {
                console.log("access_token and refresh_token expired")
                reset = true
            }

            if (reset) {
                state.loaded = false
                state.data = ""
                state.error = {}
                state.status = ""
                state.statusText = ""
                state.loggedIn = false
            }
            state.isStaff = null
        },
        reset(state) {
            state.loaded = false
            state.data = ""
            state.error = {}
            state.status = ""
            state.statusText = ""
            state.loggedIn = false
            state.isStaff = false
        }
    }
})

export const loginObtainToken = (enteredUsername, enteredPassword) => {
    return async (dispatch) => {
        const authData = await obtainAuthTokenByLogin(enteredUsername, enteredPassword)
        // console.log(authData)
        const tokenData = authData.refresh ? JSON.parse(window.atob(authData.refresh.split(".")[1])) : null
        dispatch(
            authActions.updateAuth({
                loaded: true,
                data: !authData.error ? tokenData : {},
                error: authData.error || false,
                status: authData.error ? authData.response.status : "",
                statusText: authData.error ? authData.response.statusText : "",
                loggedIn: !authData.error,
                isStaff: tokenData?.isStaff
            })
        )
        if (authData.error) {
            dispatch(
                alertActions.showNotification({
                    title: "Error",
                    alertType: "alert alert-danger",
                    body: authData.response.status === 401 ? "Username or Password is incorrect" : authData.response.statusText
                })
            )
        }
    }
}

export const authSignup = (formData) => {
    return async (dispatch) => {
        const signup = async () =>
            await postData(`${process.env.REACT_APP_BACKEND_URL}/auth/signup`, {
                headers: {
                    Authorization: null,
                    "Content-Type": "application/json",
                    accept: "application/json"
                },
                updateAccessToken: true,
                data: formData
            })
        const authData = await signup()
        dispatch(
            authActions.updateAuth({
                loaded: true,
                data: authData,
                error: authData.error || false,
                status: "",
                statusText: "",
                loggedIn: false,
                isStaff: false
            })
        )

        // This authData.error comes from collated and returned from django
        // console.log(authData.error)
        let errorMessages = ""
        if (typeof authData.error === "object") {
            errorMessages = Object.entries(authData.error)
                .map(([k, v]) => `${k}: \n${v.map((v2) => v2).join(" ")}`)
                .join("")
        } else if (authData.response && !authData.response.ok) {
            errorMessages = authData.response.statusText || "Unknown Error"
        }
        if (errorMessages) {
            dispatch(
                alertActions.showNotification({
                    title: "Error",
                    alertType: "alert alert-danger",
                    body: errorMessages
                })
            )
        }
    }
}

export const authLogout = () => {
    return async (dispatch) => {
        const logout = async () => logoutAndBlacklist()
        const authData = await logout()
        dispatch(
            authActions.updateAuth({
                loaded: true,
                data: "",
                error: authData.error || false,
                status: "",
                statusText: "",
                loggedIn: false,
                isStaff: false
            })
        )
        if (authData.error) {
            dispatch(
                alertActions.showNotification({
                    title: "Error",
                    body: (
                        <div className="alert alert-danger">
                            <p>{authData.response.statusText}</p>
                        </div>
                    )
                })
            )
        }
    }
}

export const checkAdminAccess = () => {
    return async (dispatch) => {
        const checkAdmin = async () => {
            let data = {}
            data = await getData(`${process.env.REACT_APP_BACKEND_URL}/auth/staff/check`, {
                headers: {
                    Authorization: localStorage.getItem("access_token") ? "Bearer " + localStorage.getItem("access_token") : null,
                    "Content-Type": "application/json",
                    accept: "application/json"
                },
                updateAccessToken: true
            })
            return data
        }
        const data = await checkAdmin()
        // console.log(data)
        dispatch(
            authActions.updateAuth({
                loaded: true,
                data: !data.error ? data : {},
                error: "",
                status: "",
                statusText: "",
                loggedIn: data.loggedIn,
                isStaff: data?.isStaff || false
            })
        )
    }
}

export const checkLoggedInAccess = () => {
    return async (dispatch) => {
        const checkLoggedIn = async () => {
            let data = {}
            data = await getData(`${process.env.REACT_APP_BACKEND_URL}/auth/user/check`, {
                headers: {
                    Authorization: localStorage.getItem("access_token") ? "Bearer " + localStorage.getItem("access_token") : null,
                    "Content-Type": "application/json",
                    accept: "application/json"
                },
                updateAccessToken: true
            })
            return data
        }
        const data = await checkLoggedIn()
        dispatch(
            authActions.updateAuth({
                loaded: true,
                data: !data.error ? data : {},
                error: "",
                status: "",
                statusText: "",
                loggedIn: data.loggedIn,
                isStaff: data?.isStaff || false
            })
        )
    }
}

export const authActions = authSlice.actions

export default authSlice
