export const EVENTSTATUS_LIVE = "live"
export const EVENTSTATUS_UPCOMING = "upcoming"
export const EVENTSTATUS_VODS = "vod"
export const EVENTSTATUS_COMPLETED = "completed"
export const EVENTSTATUS_DEMO = "demo"
export const EVENTSTATUS_ALL = "_all"

export const EVENTXRMODE_360 = "360"
export const EVENTXRMODE_VR = "vr"
export const EVENTXRMODE_AR = "ar"

export const STREAM_FORMAT_HLS = "hls"
export const STREAM_FORMAT_MP4 = "mp4"
export const STREAM_FORMAT_WEBRTC = "webrtc"
export const STREAM_FORMAT_WEBRTC_DEMO = "webrtc-demo"
export const STREAM_FORMAT_WEBRTC_LIVE = "webrtc-live"

export const EVENTSTATUS_LIST = {
    [EVENTSTATUS_LIVE]: "Live",
    [EVENTSTATUS_UPCOMING]: "Upcoming",
    [EVENTSTATUS_VODS]: "Video on Demand",
    [EVENTSTATUS_COMPLETED]: "Completed"
}
