// import "aframe"
import { Entity } from "aframe-react"

export function SportAssets(props) {
    return (
        <>
            {props.config.designSportsUp ? <img alt="Sport up panel" crossOrigin="anonymous" id="imgSportUp" src={props.config.designSportsUp} /> : null}
            {props.config.designSportsDown ? <img alt="Sport down panel" crossOrigin="anonymous" id="imgSportDown" src={props.config.designSportsDown} /> : null}
            {props.config.designSportPanel ? <img alt="Sport panel" crossOrigin="anonymous" id="imgSportPanel" src={props.config.designSportPanel} /> : null}
        </>
    )
}

export default function SportEntity(props) {
    let src = ""
    if (props.arrowDirection === "up") src = "#imgSportUp"
    else if (props.arrowDirection === "down") src = "#imgSportDown"
    else if (props.config.designSportPanel) src = "#imgSportPanel"

    return (
        <Entity
            data-desc={src}
            primitive="a-plane"
            material={{ transparent: true, src: src }}
            position={props.position}
            width={props.width || 2.7}
            height={props.height || 0.45}
            className="clickable"
            events={props.events}
            data-sportId={props.sportId}
        >
            {!props.arrowDirection ? <Entity primitive="a-text" text={{ value: props.text }} position={{ x: -1.3, z: 0.1 }} /> : null}
        </Entity>
    )
}
