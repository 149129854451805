import { Link, useParams } from "react-router-dom"
import Layout from "../../components/Layout/Layout"

export default function WayfindingPage() {
    const { sportId } = useParams()

    return (
        <Layout>
            <div className="container">
                <h3>
                    <Link to={`/${sportId}/ar`} className="text-decoration-none me-3">
                        &lt;
                    </Link>
                    Wayfinding Page
                </h3>
            </div>
            <div className="container mt-5 pt-5">Wayfinding Information</div>
        </Layout>
    )
}
