// import "aframe"
import { createEntityFromImageURL } from "../../utils/aframe-utils"

export function ChatAssets(props) {
    return props.config.designChatPanel ? <img alt="FXR Logo" crossOrigin="anonymous" id="imgChat" src={props.config.designChatPanel} /> : null
}

export default function ChatEntity(props) {
    return createEntityFromImageURL(props.config.designChatPanel, "#imgChat", { position: props.position || { x: -1 }, events: props.events }, "Chat")
}
