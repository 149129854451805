import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect, Route } from "react-router-dom"
import { Spinner } from "react-bootstrap"
import { checkLoggedInAccess, checkAdminAccess } from "../store/auth-slice"

function ProtectedRoute({ component: Component, ...restOfProps }) {
    const dispatch = useDispatch()
    const auth = useSelector((state) => state.auth)
    useEffect(() => {
        if (!auth.loaded) dispatch(restOfProps.adminAccess ? checkAdminAccess() : checkLoggedInAccess())
    }, [auth.loaded, restOfProps.adminAccess, dispatch])

    let route = (
        <div className="d-flex justify-content-center align-items-center mt-5">
            <Spinner animation="border" />
        </div>
    )

    if (restOfProps.adminAccess && auth.loaded && auth.loggedIn && auth.isStaff === false) {
        return (
            <div className="d-flex justify-content-center align-items-center mt-5">
                <div className="alert alert-danger px-5">Sorry, you are not authorized</div>
            </div>
        )
    }

    if (auth.loaded) {
        route = (
            <Route
                {...restOfProps}
                render={(props) =>
                    auth.loggedIn ? (
                        <Component {...props} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/auth/login",
                                state: { from: props.location }
                            }}
                        />
                    )
                }
            />
        )
    }

    return route
}

export default ProtectedRoute
