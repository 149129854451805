import { useEffect } from "react"
import { Spinner } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import Layout from "../../components/Layout/Layout"
import { fetchSportsData } from "../../store/sports-slice"
import { EVENTSTATUS_LIVE, EVENTSTATUS_VODS } from "../../utils/constants"

export default function AdminPage(props) {
    const dispatch = useDispatch()
    const sports = useSelector((state) => state.sports)

    useEffect(() => {
        if (!sports.loaded) dispatch(fetchSportsData())
    }, [sports.loaded, props.location, dispatch])

    let adminPage = (
        <>
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        </>
    )

    if (sports.loaded) {
        adminPage = (
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h1>Live Events</h1>
                        <hr />
                        {sports.data.map((s, i) => (
                            <div key={i}>
                                <h5>{s.name}</h5>
                                <div className="list-group mb-4">
                                    <Link to={`/admin/${s.url_shortcut}/${EVENTSTATUS_LIVE}`} className="list-group-item list-group-item-action">
                                        Team Config
                                    </Link>
                                    <Link to={`/admin/${s.url_shortcut}/${EVENTSTATUS_VODS}`} className="list-group-item list-group-item-action">
                                        VoDs
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="col">
                        <h1>Misc</h1>
                        <hr />
                        <h5>Event Subscribers</h5>
                        <div className="list-group mb-4">
                            <Link to={"/admin/eventsubscribers"} className="list-group-item list-group-item-action">
                                View event subscribers
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return <Layout>{adminPage}</Layout>
}
