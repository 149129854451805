// import "aframe"
import { Entity } from "aframe-react"

export function StatsBoxAssets(props) {
    return props.config.designStatsBox ? <img alt="Stats Box" crossOrigin="anonymous" id="imgStatsBox" src={props.config.designStatsBox} /> : null
}

export default function StatsBoxEntity(props) {
    return (
        <>
            <Entity
                data-desc="Stats Box Panel"
                primitive="a-plane"
                material={{
                    transparent: true,
                    src: props.config.designStatsBox ? "#imgStatsBox" : ""
                }}
                position={{ z: -4.8 }}
                width="6"
                height="6"
            >
                <Entity data-desc="Stats Box Title" primitive="a-text" position={{ x: -0.5, y: 2.5, z: 0.1 }} text={{ value: "Stats" }} />
                <Entity
                    data-desc="Stats Box Close Button"
                    primitive="a-plane"
                    material={{ transparent: true }}
                    position={{ x: 2.68, y: 2.5, z: 0.1 }}
                    width="0.5"
                    height="0.5"
                    className="clickable"
                    events={{ click: props.handleHideBoxClicked }}
                />
                <Entity primitive="a-text" position={{ x: -2.4, y: 1.8, z: 0.1 }} text={{ value: "Name: " + props.selectedStats.name || "" }} />
                <Entity primitive="a-text" position={{ x: -2.4, y: 1.0, z: 0.1 }} text={{ value: "Num: " + props.selectedStats.kitnum || "" }} />
                <Entity primitive="a-text" position={{ x: -2.4, y: 0.2, z: 0.1 }} text={{ value: "Pos: " + props.selectedStats.position || "" }} />
                <Entity primitive="a-text" position={{ x: -2.4, y: -0.6, z: 0.1 }} text={{ value: "CAP: " + props.selectedStats.appearances || "" }} />
                <Entity primitive="a-text" position={{ x: -2.4, y: -1.4, z: 0.1 }} text={{ value: "Goals: " + props.selectedStats.goals || "" }} />
            </Entity>
        </>
    )
}
