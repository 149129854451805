// import "aframe"
import { Entity, Scene } from "aframe-react"
import { useEffect, useRef, useState } from "react"
import { requestFullscreen } from "../../../utils/connection-utils"
import ArcsEntity, { ArcsAssets } from "../ArcsEntity"
import MapEntity, { MapAssets } from "../MapEntity"
import PiPBoxEntity, { PiPBoxAssets } from "../PiPBoxEntity"
import { useDispatch, useSelector } from "react-redux"
import { fetchProfileData } from "../../../store/profile-slice"
import { addToAnalytics } from "../../../store/analytics-slice"
import SliderEntity, { SliderAssets } from "../SliderEntity"

export default function AScene(props) {
    const wrapperWidth = 5
    const dispatch = useDispatch()
    const profile = useSelector((state) => state.profile)
    const [startVideo, setStartVideo] = useState(false)
    const [pauseVideo, setPauseVideo] = useState(false)
    const [currentCam, setCurrentCam] = useState(0)
    const [pip1, setPip1] = useState("")
    const [pip2, setPip2] = useState("")
    const [vid1, setVid1] = useState("")
    const [vid2, setVid2] = useState("")
    const [vid3, setVid3] = useState("")
    const [vid4, setVid4] = useState("")
    const [bufferWidth, setBufferWidth] = useState(0)
    const [bufferPosition, setBufferPosition] = useState("0 0 0.01")
    const [progressWidth, setProgressWidth] = useState(0)
    const [progressPosition, setProgressPosition] = useState("0 0 0.02")
    const progressTipWidth = 0.05
    const [progressTipPosition, setProgressTipPosition] = useState("0 0 0.03")
    const [showBufferingMsg, setShowBufferingMsg] = useState(false)
    // const [vid1CurrentTime, setVid1CurrentTime] = useState(0)

    let analyticsData = {}
    let cameraRef = useRef()
    let streamRef1 = useRef()
    let streamRef2 = useRef()
    let streamRef3 = useRef()
    let streamRef4 = useRef()
    let pipRef1 = useRef()
    let pipRef2 = useRef()
    const sceneRef = useRef()
    // eslint-disable-next-line no-unused-vars
    let intervalAnalytics = null

    useEffect(() => {
        if (!profile.loaded) dispatch(fetchProfileData())
        if (startVideo) playPauseVideo()
    }, [profile.loaded, startVideo])

    const playPauseVideo = (play = true) => {
        if (play) {
            setPauseVideo(false)
            if (props.config.video1 && props.config.video1 !== "") streamRef1.current.play()
            if (props.config.video2 && props.config.video2 !== "") streamRef2.current.play()
            if (props.config.video3 && props.config.video3 !== "") streamRef3.current.play()
            if (props.config.video4 && props.config.video4 !== "") streamRef4.current.play()
            if (props.config.pip1) pipRef1.current.play()
            if (props.config.pip2) pipRef2.current.play()
        } else {
            setPauseVideo(true)
            if (props.config.video1 && props.config.video1 !== "") streamRef1.current.pause()
            if (props.config.video2 && props.config.video2 !== "") streamRef2.current.pause()
            if (props.config.video3 && props.config.video3 !== "") streamRef3.current.pause()
            if (props.config.video4 && props.config.video4 !== "") streamRef4.current.pause()
            if (props.config.pip1) pipRef1.current.pause()
            if (props.config.pip2) pipRef2.current.pause()
        }
    }
    const setCurrentTime = (currentTime) => {
        if (props.config.video1 && streamRef1.current && streamRef1.current.readyState > 0) streamRef1.current.currentTime = currentTime
        if (props.config.video2 && streamRef2.current && streamRef2.current.readyState > 0) streamRef2.current.currentTime = currentTime
        if (props.config.video3 && streamRef3.current && streamRef3.current.readyState > 0) streamRef3.current.currentTime = currentTime
        if (props.config.video4 && streamRef4.current && streamRef4.current.readyState > 0) streamRef4.current.currentTime = currentTime
        if (props.config.pip1 && pipRef1.current && pipRef1.current.readyState > 0) pipRef1.current.currentTime = currentTime
        if (props.config.pip2 && pipRef2.current && pipRef2.current.readyState > 0) pipRef2.current.currentTime = currentTime
    }

    const handlePlayOnClick = () => {
        setStartVideo(true)
        requestFullscreen(sceneRef)

        if (props.analyticsData.enabled) {
            intervalAnalytics = setInterval(() => {
                analyticsData = {
                    sportId: props.analyticsData.sportId,
                    configId: props.analyticsData.configId,
                    teamId: props.analyticsData.teamId,
                    payload: {
                        position: cameraRef.current.el.object3D.position,
                        rotation: cameraRef.current.el.object3D.rotation,
                        scale: cameraRef.current.el.object3D.scale
                    }
                }
                dispatch(addToAnalytics(analyticsData))
            }, 5000)
        }
    }

    const handleCamClicked = (e) => setCurrentCam(e.target.getAttribute("data-cam-id"))

    const handleOnWrapperClicked = (e) => {
        var point = cameraRef.current.el.components.raycaster.raycaster.intersectObject(e.target.object3D, true)[0].point
        var x_offset = e.target.object3D.worldToLocal(point).x
        var unit_offset = x_offset / wrapperWidth + 0.5
        setCurrentTime(unit_offset * streamRef1.current.duration)
    }

    const handleOnProgress = (e) => {
        if (isNaN(e.target.duration))
            // duration is NotaNumber at Beginning.
            return

        let w = (e.target.buffered.end(0) / e.target.duration) * wrapperWidth
        if (w == 0) w = 0.001
        let x = w / 2 - wrapperWidth / 2

        setBufferWidth(w)
        setBufferPosition(x + " 0 0.01")
    }
    const handleOnTimeUpdate = (e) => {
        if (isNaN(e.target.duration))
            // duration is NotaNumber at Beginning.
            return

        let w = (e.target.currentTime / e.target.duration) * wrapperWidth
        if (w == 0) w = 0.001
        let x = w / 2 - wrapperWidth / 2
        let sk_x = w - wrapperWidth / 2
        setProgressWidth(w)
        setProgressPosition(x + " 0 0.02")
        setProgressTipPosition(sk_x + " 0 0.03")
    }
    const handlePlayPausedClicked = () => {
        playPauseVideo(pauseVideo)
    }
    const handleResetClicked = () => {
        setCurrentTime(0)
        playPauseVideo()
    }
    const handleRewindClicked = () => {
        setCurrentTime(streamRef1.current.currentTime - 5)
    }
    const handleForwardClicked = () => {
        setCurrentTime(streamRef1.current.currentTime + 5)
    }
    const handleOnWaiting = () => {
        setShowBufferingMsg(true)
    }
    const handleOnPlaying = () => {
        setShowBufferingMsg(false)
    }

    if (props.config.video1 !== vid1) setVid1(props.config.video1)
    if (props.config.video2 !== vid2) setVid2(props.config.video2)
    if (props.config.video3 !== vid3) setVid3(props.config.video3)
    if (props.config.video4 !== vid4) setVid4(props.config.video4)
    if (props.config.pip1 !== pip1) setPip1(props.config.pip1)
    if (props.config.pip2 !== pip2) setPip2(props.config.pip2)

    return (
        <Scene ref={sceneRef} vr-mode-ui="enabled: false">
            <a-assets>
                <img alt="Play button" id="imgPlay" crossOrigin="anonymous" src={process.env.REACT_APP_LOCATION_S3 + "/core/images/play.png"} />
                <SliderAssets {...props} />
                <MapAssets {...props} />
                <ArcsAssets {...props} />
                <PiPBoxAssets {...props} pip1Ref={pipRef1} pip2Ref={pipRef2} />
                {props.config.video1 && (
                    <video
                        ref={streamRef1}
                        id="vidStream1"
                        crossOrigin="anonymous"
                        src={vid1}
                        controls
                        muted
                        onProgress={handleOnProgress}
                        onTimeUpdate={handleOnTimeUpdate}
                        onWaiting={handleOnWaiting}
                        onPlaying={handleOnPlaying}
                    />
                )}
                {props.config.video2 && <video ref={streamRef2} id="vidStream2" crossOrigin="anonymous" src={vid2} controls muted />}
                {props.config.video3 && <video ref={streamRef3} id="vidStream3" crossOrigin="anonymous" src={vid3} controls muted />}
                {props.config.video4 && <video ref={streamRef4} id="vidStream4" crossOrigin="anonymous" src={vid4} controls muted />}
                <img alt="Transparent Image" crossOrigin="anonymous" id="imgTransparent" src={`${process.env.REACT_APP_LOCATION_S3}/core/images/transparent.png`} />
            </a-assets>
            <Entity primitive="a-camera" position={{ y: 1.6 }} raycaster={{ objects: [".clickable"] }} cursor={{ rayOrigin: "mouse", fuse: false }} ref={cameraRef}>
                {!startVideo ? (
                    <Entity
                        primitive="a-plane"
                        position={{ z: -4.9 }}
                        material={{ transparent: true, src: "#imgPlay" }}
                        className="clickable"
                        events={{ click: handlePlayOnClick }}
                    />
                ) : null}
                {/* visual entities */}
                <ArcsEntity {...props} />
                {startVideo && (
                    <>
                        <MapEntity {...props} currentCam={currentCam} handleCamClicked={handleCamClicked} position={{ x: 0, y: 2, z: -5 }} />
                        <PiPBoxEntity {...props} position={{ y: -3, z: -5 }} />
                        <SliderEntity
                            wrapperWidth={wrapperWidth}
                            bufferWidth={bufferWidth}
                            bufferPosition={bufferPosition}
                            progressWidth={progressWidth}
                            progressPosition={progressPosition}
                            progressTipWidth={progressTipWidth}
                            progressTipPosition={progressTipPosition}
                            handleOnWrapperClicked={handleOnWrapperClicked}
                            handlePlayPausedClicked={handlePlayPausedClicked}
                            handleResetClicked={handleResetClicked}
                            handleRewindClicked={handleRewindClicked}
                            handleForwardClicked={handleForwardClicked}
                            showBufferingMsg={showBufferingMsg}
                            pauseVideo={pauseVideo}
                        ></SliderEntity>
                    </>
                )}
            </Entity>
            <Entity primitive="a-sky" material={{ color: "black" }} />
            {startVideo ? <Entity primitive="a-videosphere" src={"#vidStream" + (parseInt(currentCam) + 1)} /> : null}
        </Scene>
    )
}
