import { Modal } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { alertActions } from "../../store/alert-slice"

export default function ModalAlert() {
    const alert = useSelector((state) => state.alert)
    const dispatch = useDispatch()
    const handleClose = () => dispatch(alertActions.hideNotification())

    let alertModal = null
    if (alert.notification) {
        alertModal = (
            <Modal show={true} onHide={handleClose} keyboard={false}>
                <Modal.Header>
                    <Modal.Title>{alert.notification.title || "Warning"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={alert.alertType}>{alert.notification.body || ""}</div>
                </Modal.Body>
                {alert.notification.footer ? <Modal.Footer>{alert.notification.footer}</Modal.Footer> : null}
            </Modal>
        )
    }
    return alertModal
}
