import Layout from "../../components/Layout/Layout"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { useEffect } from "react"
import { configActions, fetchConfigData } from "../../store/config-slice"
import { Spinner } from "react-bootstrap"
import { EVENTSTATUS_LIVE, EVENTSTATUS_VODS } from "../../utils/constants"

export default function AdminTeamConfigPage() {
    const dispatch = useDispatch()
    const config = useSelector((state) => state.config)
    const { sportId, eventStatus } = useParams()
    useEffect(() => {
        dispatch(configActions.clearConfig())
        dispatch(fetchConfigData(sportId, eventStatus))
    }, [sportId, eventStatus, dispatch])

    let teamConfigPage = (
        <div className="d-flex justify-content-center">
            <Spinner animation="border" />
        </div>
    )

    if (config.loaded) {
        // console.log(config)
        teamConfigPage = (
            <>
                <table className="table table-bordered table-hover mt-3">
                    <colgroup>
                        <col style={{ width: "50%" }} />
                        <col style={{ width: "50%" }} />
                    </colgroup>
                    <thead>
                        <tr className="bg-dark">
                            <th className="text-white">{eventStatus === EVENTSTATUS_VODS ? "VoD Name" : "Team"}</th>
                            <th className="text-white"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {config.data.configs
                            ? config.data.configs.map((c, i) => {
                                  let name = c.fields.vodName
                                  if (eventStatus === EVENTSTATUS_LIVE) name = config.data.teams.find((t) => t.pk === c.fields.team).fields.name
                                  return (
                                      <tr key={i}>
                                          <td>{name}</td>
                                          <td>
                                              <Link to={`/admin/${sportId}/${eventStatus}/${c.pk}`} className="btn btn-warning">
                                                  Edit
                                              </Link>
                                              <Link to={`/${sportId}/${eventStatus}/${c.pk}`} className="btn btn-primary ms-2">
                                                  View
                                              </Link>
                                              <Link to={`/${sportId}/${eventStatus}/${c.pk}?vr=1`} className="btn btn-primary ms-2">
                                                  View in VR
                                              </Link>
                                          </td>
                                      </tr>
                                  )
                              })
                            : null}
                    </tbody>
                </table>
            </>
        )
    }

    return (
        <Layout sportId={sportId}>
            <div className="container">
                <h3>
                    <Link to={"/admin"} className="text-decoration-none me-3">
                        &lt;
                    </Link>
                    {eventStatus === EVENTSTATUS_LIVE ? "Team Configs" : "VoDs"} - {sportId}
                </h3>
                {teamConfigPage}
            </div>
        </Layout>
    )
}
