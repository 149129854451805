import { createSlice } from "@reduxjs/toolkit"
import { getData, postData } from "../utils/connection-utils"

const profileSlice = createSlice({
    name: "profile",
    initialState: {
        data: [],
        error: "",
        loaded: false
    },
    reducers: {
        updateProfileState(state, action) {
            state.data = action.payload.data || []
            state.error = action.payload.error
            state.loaded = action.payload.loaded
        },
        clearProfile(state) {
            state.data = []
            state.error = ""
            state.loaded = false
        }
    }
})

export const fetchProfileData = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            let data = {}
            data = await getData(`${process.env.REACT_APP_BACKEND_URL}/profile/get`, {
                headers: {
                    Authorization: localStorage.getItem("access_token") ? "Bearer " + localStorage.getItem("access_token") : null,
                    "Content-Type": "application/json",
                    accept: "application/json"
                },
                updateAccessToken: true
            })
            return data
        }

        const profileData = await fetchData()
        dispatch(
            profileActions.updateProfileState({
                data: !profileData.error ? profileData : [],
                loaded: true,
                error: profileData.error || ""
            })
        )
    }
}
export const updateProfile = (formData) => {
    return async (dispatch) => {
        const updateProfileData = async () => {
            let data = {}
            data = await postData(`${process.env.REACT_APP_BACKEND_URL}/profile/update`, {
                headers: {
                    Authorization: localStorage.getItem("access_token") ? "Bearer " + localStorage.getItem("access_token") : null,
                    "Content-Type": "application/json",
                    accept: "application/json"
                },
                updateAccessToken: true,
                data: formData
            })
            return data
        }
        await updateProfileData()
        await dispatch(fetchProfileData())
    }
}

export const profileActions = profileSlice.actions

export default profileSlice
