// import "aframe"
import { Entity, Scene } from "aframe-react"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchProfileData } from "../../../store/profile-slice"

export default function AScene(props) {
    const dispatch = useDispatch()
    const profile = useSelector((state) => state.profile)
    const [startVideo, setStartVideo] = useState(false)
    // const [theatreMode, setTheatreMode] = useState(false)
    const [currentStreamData, setCurrentStreamData] = useState({
        cam: "1",
        url: "",
        updateStream: false
    })
    const [currentPip1, setCurrentPip1] = useState("")
    const [currentPip2, setCurrentPip2] = useState("")

    const cameraMaterial = {
        transparent: true,
        src: props.config.designCamPanel ? "#imgCamPanel" : ""
    }
    const cameraMaterialActive = {
        transparent: true,
        src: props.config.designCamPanelActive ? "#imgCamPanelActive" : ""
    }

    let streamRef = useRef()
    const sceneRef = useRef()

    const updateStreamByCam = (currentCamId) => {
        setCurrentStreamData({
            cam: currentCamId,
            url: props.config[`video${parseInt(currentCamId) + 1}`] || props.config.video1
        })
    }

    useEffect(() => {
        if (!profile.loaded) dispatch(fetchProfileData())
    }, [profile.loaded])

    const handlePlayOnClick = () => {
        setStartVideo(true)
        sceneRef.current.el.enterVR()
        streamRef.current.play()
    }

    const handleCamClicked = (e) => {
        updateStreamByCam(e.target.getAttribute("data-cam-id"))
        streamRef.current.play()
    }
    // const handleBetButtonClicked = () => setShowBetPopup(true)
    // const handleHideBoxClicked = () => setShowBetPopup(false)
    // const handleFXRClicked = () => {
    //     if (startVideo) setTheatreMode(!theatreMode)
    // }
    // const handleChatClicked = () => {
    //     window.open(`https://s3.ap-southeast-2.amazonaws.com/chat.fxr360.com/index.html?user=${profile.data?.username || ""}`, "_blank").focus()
    // }

    if (props.config[`video${parseInt(currentStreamData.cam) + 1}`] !== currentStreamData.url) updateStreamByCam(currentStreamData.cam)
    if (props.config.pip1 !== currentPip1) setCurrentPip1(props.config.pip1)
    if (props.config.pip2 !== currentPip2) setCurrentPip2(props.config.pip2)

    console.log(currentStreamData)
    return (
        <Scene ref={sceneRef} vr-mode-ui="enabled: false">
            <a-assets>
                <img alt="Play button" id="imgPlay" crossOrigin="anonymous" src={process.env.REACT_APP_LOCATION_S3 + "/core/images/play.png"} />
                {props.config.designCamPanel ? <img alt="Camera" id="imgCamPanel" crossOrigin="anonymous" src={props.config.designCamPanel} /> : null}
                {props.config.designCamPanelActive ? <img alt="Camera" id="imgCamPanelActive" crossOrigin="anonymous" src={props.config.designCamPanelActive} /> : null}
                <video ref={streamRef} id="vidStream" crossOrigin="anonymous" src={currentStreamData.url} controls muted />
            </a-assets>
            {!startVideo ? (
                <Entity primitive="a-camera" position={{ y: 1.6 }} raycaster={{ objects: [".clickable"] }} cursor={{ rayOrigin: "mouse", fuse: false }}>
                    <Entity
                        primitive="a-plane"
                        position={{ z: -4.9 }}
                        material={{ transparent: true, src: "#imgPlay" }}
                        className="clickable"
                        events={{ click: handlePlayOnClick }}
                    />
                </Entity>
            ) : null}
            {startVideo ? (
                <>
                    <Entity primitive="a-camera" position={{ y: 1.6 }}>
                        <a-entity
                            cursor="fuse: true; fuseTimeout: 500"
                            position="0 0 -1"
                            geometry="primitive: ring; radiusInner: 0.02; radiusOuter: 0.03"
                            material="color: aqua; shader: flat"
                        />
                    </Entity>
                    <Entity
                        data-cam-id="1"
                        primitive="a-plane"
                        material={currentStreamData.cam === "1" ? cameraMaterialActive : cameraMaterial}
                        position={{ x: -1.5, y: -1, z: -4.9 }}
                        height="0.5"
                        width="0.5"
                        events={{ click: handleCamClicked }}
                    />
                    <Entity
                        data-cam-id="2"
                        primitive="a-plane"
                        material={currentStreamData.cam === "2" ? cameraMaterialActive : cameraMaterial}
                        position={{ x: -0.5, y: -1, z: -4.9 }}
                        height="0.5"
                        width="0.5"
                        events={{ click: handleCamClicked }}
                    />
                    <Entity
                        data-cam-id="3"
                        primitive="a-plane"
                        material={currentStreamData.cam === "3" ? cameraMaterialActive : cameraMaterial}
                        position={{ x: 0.5, y: -1, z: -4.9 }}
                        height="0.5"
                        width="0.5"
                        events={{ click: handleCamClicked }}
                    />
                    <Entity
                        data-cam-id="4"
                        primitive="a-plane"
                        material={currentStreamData.cam === "4" ? cameraMaterialActive : cameraMaterial}
                        position={{ x: 1.5, y: -1, z: -4.9 }}
                        height="0.5"
                        width="0.5"
                        events={{ click: handleCamClicked }}
                    />
                </>
            ) : null}

            <Entity primitive="a-videosphere" src="#vidStream" />
        </Scene>
    )
}
