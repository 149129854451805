import { createSlice } from "@reduxjs/toolkit"
import { getData } from "../utils/connection-utils"
import { alertActions } from "./alert-slice"

const teamsSlice = createSlice({
    name: "teams",
    initialState: {
        data: [],
        loaded: false,
        error: ""
    },
    reducers: {
        updateTeams(state, action) {
            state.data = action.payload.data
            state.loaded = action.payload.loaded
            state.error = action.payload.error
        }
    }
})

export const fetchTeamsDataById = (teamId) => {
    return async (dispatch) => {
        const fetchData = async () => {
            let teamsData = []
            const data = await getData(`${process.env.REACT_APP_BACKEND_URL}/teams/get/${teamId}`)
            data.map((d) => teamsData.push({ ...d.fields }))
            return teamsData
        }

        const teamsData = await fetchData()
        dispatch(
            teamsActions.updateTeams({
                data: !teamsData.error ? teamsData : [],
                loaded: true,
                error: teamsData.error || ""
            })
        )
        if (teamsData.error) {
            dispatch(
                alertActions.showNotification({
                    title: "Error",
                    body: (
                        <div className="alert alert-danger">
                            <p>{teamsData.error}</p>
                        </div>
                    )
                })
            )
        }
    }
}

export const teamsActions = teamsSlice.actions

export default teamsSlice
