import { Entity } from "aframe-react"

export const createEntityFromImageURL = (imageURL, imageSrcId, entityAttributes, imageURLFallbackText) => {
    let entity = null
    if (imageURL) {
        entity = (
            <Entity
                primitive="a-plane"
                material={{ transparent: true, src: imageSrcId }}
                position={entityAttributes.position}
                className="clickable"
                events={entityAttributes.events}
            />
        )
    } else {
        entity = (
            <Entity
                primitive="a-plane"
                material={{ transparent: true, src: "#imgTransparent" }}
                position={entityAttributes.position}
                className="clickable"
                events={entityAttributes.events}
            >
                <Entity primitive="a-text" value={imageURLFallbackText} scale={{ x: 1.5, y: 1.5, z: 1.5 }} position={{ x: -0.5 }} />
            </Entity>
        )
    }
    return entity
}
