import { createSlice } from "@reduxjs/toolkit"
import { getData, postData } from "../utils/connection-utils"
import { alertActions } from "./alert-slice"

const eventsSubscribersSlice = createSlice({
    name: "eventSubscribers",
    initialState: {
        data: [],
        loaded: false
    },
    reducers: {
        updateEventSubscribers(state, action) {
            state.data = action.payload.data || []
            state.loaded = action.payload.loaded
        },
        clearEventSubscribers(state) {
            state.data = []
            state.loaded = false
        }
    }
})

export const fetchEventSubscribersData = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            let data = await getData(`${process.env.REACT_APP_BACKEND_URL}/event-subscribers/get`, {
                headers: {
                    Authorization: localStorage.getItem("access_token") ? "Bearer " + localStorage.getItem("access_token") : null,
                    "Content-Type": "application/json",
                    accept: "application/json"
                },
                updateAccessToken: true
            })
            return data
        }

        const eventSubscribersData = await fetchData()
        dispatch(
            eventSubscribersActions.updateEventSubscribers({
                data: !eventSubscribersData.error ? eventSubscribersData : [],
                loaded: true,
                error: eventSubscribersData.error || ""
            })
        )
    }
}
export const checkEventSubscribersData = (id) => {
    return async (dispatch) => {
        const checkData = async () => {
            let data = await getData(`${process.env.REACT_APP_BACKEND_URL}/event-subscribers/get/${id}`, {
                headers: {
                    Authorization: localStorage.getItem("access_token") ? "Bearer " + localStorage.getItem("access_token") : null,
                    "Content-Type": "application/json",
                    accept: "application/json"
                },
                updateAccessToken: true
            })
            return data
        }

        dispatch(eventSubscribersActions.clearEventSubscribers())
        const eventSubscribersData = await checkData()
        dispatch(
            eventSubscribersActions.updateEventSubscribers({
                data: !eventSubscribersData.error ? eventSubscribersData : [],
                loaded: true,
                error: eventSubscribersData.error || ""
            })
        )
    }
}
export const updateEventSubscriber = (id) => {
    return async (dispatch) => {
        const updateData = async () => {
            let data = {}
            data = await postData(`${process.env.REACT_APP_BACKEND_URL}/event-subscribers/update`, {
                headers: {
                    Authorization: localStorage.getItem("access_token") ? "Bearer " + localStorage.getItem("access_token") : null,
                    "Content-Type": "application/json",
                    accept: "application/json"
                },
                updateAccessToken: true,
                data: { id: id }
            })
            return data
        }
        await updateData()
        dispatch(eventSubscribersActions.clearEventSubscribers())
        dispatch(checkEventSubscribersData(id))
    }
}

export const emailEventSubscriber = (eventSubscriberId) => {
    return async (dispatch) => {
        const emailSubscriber = async () => {
            let data = {}
            data = await postData(`${process.env.REACT_APP_BACKEND_URL}/event-subscribers/email`, {
                headers: {
                    Authorization: localStorage.getItem("access_token") ? "Bearer " + localStorage.getItem("access_token") : null,
                    "Content-Type": "application/json",
                    accept: "application/json"
                },
                updateAccessToken: true,
                data: { event_subscriber_id: eventSubscriberId }
            })
            console.log(data)
            return data
        }
        const result = await emailSubscriber()
        let title = "Success"
        let body = "Email(s) has been sent successfully."
        if (result.error) {
            title = "Error"
            body = result.error
            if (result.response?.status) body = `${result.response.status}: ${result.response.statusText}`
        }
        dispatch(
            alertActions.showNotification({
                title: title,
                body: body
            })
        )
    }
}

export const eventSubscribersActions = eventsSubscribersSlice.actions

export default eventsSubscribersSlice
