// import "aframe"
import { Entity } from "aframe-react"

export function MapAssets(props) {
    return (
        <>
            {props.config.designMap ? <img alt="Map" id="imgMap" crossOrigin="anonymous" src={props.config.designMap} /> : null}
            {props.config.designCamPanel ? <img alt="Camera" id="imgCamPanel" crossOrigin="anonymous" src={props.config.designCamPanel} /> : null}
            {props.config.designCamPanelActive ? <img alt="Camera Active" id="imgCamPanelActive" crossOrigin="anonymous" src={props.config.designCamPanelActive} /> : null}
        </>
    )
}

export default function MapEntity(props) {
    const cameraMaterial = {
        transparent: true,
        src: props.config.designCamPanel ? "#imgCamPanel" : ""
    }
    const cameraMaterialActive = {
        transparent: true,
        src: props.config.designCamPanelActive ? "#imgCamPanelActive" : ""
    }

    let cameras = {}
    Object.entries(props.config)
        // eslint-disable-next-line no-unused-vars
        .filter(([k, v]) => k.toLowerCase().indexOf("locationcam") === 0)
        .forEach(([k, v]) => (cameras[k] = v))
    // console.log(cameras)

    return (
        <Entity
            data-desc="Map Panel"
            primitive="a-plane"
            material={{ transparent: true, src: props.config.designMap ? "#imgMap" : "" }}
            position={props.position}
            width={props.width || "4.5"}
            height={props.height || "6.2"}
        >
            {Object.values(cameras).map((v, i) =>
                v !== "" ? (
                    <Entity
                        key={i}
                        data-desc={"Camera " + i}
                        data-cam-id={i}
                        primitive="a-plane"
                        material={props.currentCam === String(i) ? cameraMaterialActive : cameraMaterial}
                        position={v}
                        height="0.5"
                        width="0.5"
                        className="clickable"
                        events={{ click: props.handleCamClicked }}
                    />
                ) : null
            )}
        </Entity>
    )
}
