import "aframe"
import { Entity, Scene } from "aframe-react"
import { useEffect, useRef, useState } from "react"
import ReactHlsPlayer from "react-hls-player"
import { requestFullscreen } from "../../../utils/connection-utils"
import { EVENTSTATUS_DEMO, EVENTSTATUS_LIVE, STREAM_FORMAT_HLS, STREAM_FORMAT_MP4, STREAM_FORMAT_WEBRTC } from "../../../utils/constants"
import { initializeWebRTCPlayer } from "../../../utils/webrtc"
import ArcsEntity, { ArcsAssets } from "../ArcsEntity"
import BetBoxEntity, { BetBoxAssets } from "../BetBoxEntity"
import LogoFXREntity, { LogoFXRAssets } from "../LogoFXREntity"
import LogoXREntity, { LogoXRAssets } from "../LogoXREntity"
import MapEntity, { MapAssets } from "../MapEntity"
import PiPBoxEntity, { PiPBoxAssets } from "../PiPBoxEntity"
import { PlayerAssets } from "../PlayerEntity"
import SportBoxEntity, { SportBoxAssets } from "../SportBoxEntity"
import { SportAssets } from "../SportEntity"
import { StatsBoxAssets } from "../StatsBoxEntity"

export default function AFrameScene(props) {
    const [startVideo, setStartVideo] = useState(false)
    const [showBetPopup, setShowBetPopup] = useState(false)
    const [theatreMode, setTheatreMode] = useState(false)
    const [currentStreamData, setCurrentStreamData] = useState({ cam: "0", url: "", updateStream: false })
    const [currentPip1, setCurrentPip1] = useState("")
    const [currentPip2, setCurrentPip2] = useState("")
    const previousStreamURL = useRef(currentStreamData.url)
    const previousPip1 = useRef(currentPip1)
    const previousPip2 = useRef(currentPip2)
    const [webRTCAdaptorStream, setWebRTCAdaptorStream] = useState(null)
    const [webRTCAdaptorPip1, setWebRTCAdaptorPip1] = useState(null)
    const [webRTCAdaptorPip2, setWebRTCAdaptorPip2] = useState(null)

    let streamRef = useRef()
    let pip1Ref = useRef()
    let pip2Ref = useRef()
    const sceneRef = useRef()

    const updateStreamByCam = (currentCamId) => {
        setCurrentStreamData({
            cam: currentCamId,
            url: props.config[`video${parseInt(currentCamId) + 1}`] || props.config.video1
        })
    }

    useEffect(() => {
        if (startVideo) {
            if (previousStreamURL.current !== currentStreamData.url) {
                if (streamRef.current) {
                    if (props.streamFormat === STREAM_FORMAT_WEBRTC) {
                        webRTCAdaptorStream.play(currentStreamData.url, "")
                    } else {
                        streamRef.current.play()
                    }
                }
                previousStreamURL.current = currentStreamData.url
            }
            if (previousPip1.current !== currentPip1) {
                if (pip1Ref.current) {
                    if (props.streamFormat === STREAM_FORMAT_WEBRTC) {
                        webRTCAdaptorPip1.play(currentPip1, "")
                    } else {
                        pip1Ref.current.play()
                    }
                }
                previousPip1.current = currentPip1
            }
            if (previousPip2.current !== currentPip2) {
                if (pip2Ref.current) {
                    if (props.streamFormat === STREAM_FORMAT_WEBRTC) {
                        webRTCAdaptorPip2.play(currentPip2, "")
                    } else {
                        pip2Ref.current.play()
                    }
                }
                previousPip2.current = currentPip2
            }
        }
    }, [startVideo, currentStreamData.url, currentPip1, currentPip2, props.streamFormat])
    useEffect(() => {
        if (props.streamFormat === STREAM_FORMAT_WEBRTC) {
            setWebRTCAdaptorStream(initializeWebRTCPlayer(currentStreamData.url, null, streamRef))
            setWebRTCAdaptorPip1(initializeWebRTCPlayer(currentPip1, null, pip1Ref))
            setWebRTCAdaptorPip2(initializeWebRTCPlayer(currentPip2, null, pip2Ref))
        }
    }, [currentStreamData.url, currentPip1, currentPip2, props.streamFormat])

    const handlePlayOnClick = () => {
        setStartVideo(true)
        requestFullscreen(sceneRef)
    }

    const handleCamClicked = (e) => updateStreamByCam(e.target.getAttribute("data-cam-id"))
    const handleBetButtonClicked = () => setShowBetPopup(true)
    const handleHideBoxClicked = () => setShowBetPopup(false)
    const handleFXRClicked = () => {
        if (startVideo) setTheatreMode(!theatreMode)
    }

    if (props.config[`video${parseInt(currentStreamData.cam) + 1}`] !== currentStreamData.url) updateStreamByCam(currentStreamData.cam)
    if (props.config.pip1 !== currentPip1) setCurrentPip1(props.config.pip1)
    if (props.config.pip2 !== currentPip2) setCurrentPip2(props.config.pip2)

    const showPlayerBox = (props.eventStatus === EVENTSTATUS_LIVE || props.eventStatus === EVENTSTATUS_DEMO) & !theatreMode
    const showMap = !theatreMode
    const showBet = (props.config.locationBet !== "") & !theatreMode

    return (
        <Scene ref={sceneRef} vr-mode-ui="enabled: false">
            <a-assets>
                <img alt="Play button" id="imgPlay" crossOrigin="anonymous" src={process.env.REACT_APP_LOCATION_S3 + "/core/images/play.png"} />
                <LogoXRAssets {...props} />
                <LogoFXRAssets {...props} />
                <ArcsAssets {...props} />
                {showPlayerBox ? (
                    <>
                        <PlayerAssets {...props} />
                        <StatsBoxAssets {...props} />
                    </>
                ) : null}
                {props.eventStatus === EVENTSTATUS_LIVE ? (
                    <>
                        <SportBoxAssets {...props} />
                        <SportAssets {...props} />
                    </>
                ) : null}
                {showBet ? <BetBoxAssets {...props} /> : null}
                <MapAssets {...props} />
                <PiPBoxAssets {...props} pip1Ref={pip1Ref} pip2Ref={pip2Ref} />
                {props.config.designTempBox ? <img alt="TempBox" id="imgTempBox" crossOrigin="anonymous" src={props.config.designTempBox} /> : null}
                {props.config.designLogoExtra ? <img alt="LogoExtra" id="imgLogoExtra" crossOrigin="anonymous" src={props.config.designLogoExtra} /> : null}
                {props.config.ad ? <img alt="Ad" id="imgAd" crossOrigin="anonymous" src={props.config.ad} /> : null}
                {props.config.designBet ? <img alt="Bet Button" id="imgBetButton" crossOrigin="anonymous" src={props.config.designBet} /> : null}
                {props.streamFormat === STREAM_FORMAT_HLS && (
                    <ReactHlsPlayer playerRef={streamRef} id="vidStream" crossOrigin="anonymous" src={currentStreamData.url} controls muted></ReactHlsPlayer>
                )}
                {props.streamFormat === STREAM_FORMAT_MP4 && <video ref={streamRef} id="vidStream" crossOrigin="anonymous" src={currentStreamData.url} controls muted />}
                <img alt="Transparent Image" crossOrigin="anonymous" id="imgTransparent" src={`${process.env.REACT_APP_LOCATION_S3}/core/images/transparent.png`} />
            </a-assets>
            {props.streamFormat === STREAM_FORMAT_WEBRTC && <video ref={streamRef} id="vidStream" crossOrigin="anonymous" src={currentStreamData.url} muted autoPlay={true} />}
            {props.streamFormat === STREAM_FORMAT_WEBRTC && <video ref={pip1Ref} id="vidPip1" crossOrigin="anonymous" src={props.config.pip1} autoPlay={true}></video>}
            {props.streamFormat === STREAM_FORMAT_WEBRTC && <video ref={pip2Ref} id="vidPip2" crossOrigin="anonymous" src={props.config.pip2} autoPlay={true} muted></video>}
            <Entity primitive="a-camera" position={{ y: 1.6 }} raycaster={{ objects: [".clickable"] }} cursor={{ rayOrigin: "mouse", fuse: false }}>
                {!startVideo ? (
                    <Entity
                        primitive="a-plane"
                        position={{ z: -4.9 }}
                        material={{ transparent: true, src: "#imgPlay" }}
                        className="clickable"
                        events={{ click: handlePlayOnClick }}
                    />
                ) : null}
                {/* visual entities */}
                <ArcsEntity {...props} width="20" y="3.5" x="0" height="3.5" />
                <Entity position={{ y: 3.5, z: -5 }} scale={{ x: 0.7, y: 0.7, z: 0.7 }}>
                    <LogoXREntity {...props} position={{ x: -2.5 }} width="1.5" />
                    <Entity primitive="a-plane" material={{ transparent: true, src: props.config.ad ? "#imgAd" : "" }} position={{ x: 2.5 }} width="2" />
                    <Entity primitive="a-plane" material={{ transparent: true, src: props.config.designTempBox ? "#imgTempBox" : "" }} position={{ x: 0 }} width="2" height="1.5" />
                </Entity>
                <Entity position={{ x: 7.3 + (props.adjust || 0), y: 3.5, z: -5 }} scale={{ x: 0.7, y: 0.7, z: 0.7 }}>
                    {props.eventStatus === EVENTSTATUS_LIVE ? <SportBoxEntity {...props} /> : null}
                    <LogoFXREntity {...props} events={{ click: handleFXRClicked }} position={{ x: 1 }} />
                </Entity>
                <Entity position={{ x: -7.3 + (props.adjust || 0), y: 3.5, z: -5 }} scale={{ x: 0.7, y: 0.7, z: 0.7 }}>
                    <Entity primitive="a-plane" material={{ transparent: true, src: props.config.designLogoExtra ? "#imgLogoExtra" : "" }} width="2" />
                </Entity>
                {startVideo && (
                    <>
                        {showMap && (
                            <MapEntity
                                {...props}
                                currentCam={currentStreamData.cam}
                                handleCamClicked={handleCamClicked}
                                position={{ x: 5.6 + (props.adjust || 0), y: -0.2, z: -5 }}
                                height="3.5"
                                width="4"
                            />
                        )}
                        <PiPBoxEntity {...props} position={{ y: -3.6, z: -5 }} withoutAd={true} />
                        {showBet && (
                            <Entity
                                primitive="a-plane"
                                material={{ transparent: true, src: props.config.designBet ? "#imgBetButton" : "" }}
                                position={props.config.locationBet}
                                height="0.5"
                                className="clickable"
                                events={{ click: handleBetButtonClicked }}
                            />
                        )}
                        {showBetPopup && <BetBoxEntity config={props.config} handleHideBoxClicked={handleHideBoxClicked} />}
                    </>
                )}
            </Entity>
            <Entity primitive="a-sky" material={{ color: "black" }} />
            {startVideo ? <Entity primitive="a-videosphere" src="#vidStream" /> : null}
        </Scene>
    )
}
