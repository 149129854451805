import { createSlice } from "@reduxjs/toolkit"
import { postData } from "../utils/connection-utils"

const analyticsSlice = createSlice({
    name: "analytics",
    initialState: {
        data: [],
        loaded: false
    },
    reducers: {
        addToAnalytics(state, action) {
            state.data = action.payload.data || []
            state.loaded = action.payload.loaded
        }
    }
})

export const addToAnalytics = (analyticsData) => {
    return async () => {
        const addData = async () => {
            let data = {}
            console.log("here")
            data = await postData(`${process.env.REACT_APP_BACKEND_URL}/analytics/add`, {
                headers: {
                    Authorization: localStorage.getItem("access_token") ? "Bearer " + localStorage.getItem("access_token") : null,
                    "Content-Type": "application/json",
                    accept: "application/json"
                },
                updateAccessToken: true,
                data: { analyticsData: analyticsData }
            })
            return data
        }
        const data = await addData()
        console.log(data)
    }
}

export const AnalyticsActions = analyticsSlice.actions

export default analyticsSlice
