// import "aframe"
import { Entity } from "aframe-react"

export function PlayerAssets(props) {
    return (
        <>
            {props.config.designPlayerUp ? <img alt="Player Up panel" crossOrigin="anonymous" id="imgPlayerUp" src={props.config.designPlayerUp} /> : null}
            {props.config.designPlayerDown ? <img alt="Player Down panel" crossOrigin="anonymous" id="imgPlayerDown" src={props.config.designPlayerDown} /> : null}
            {props.config.designPlayerPanel ? <img alt="Player panel" crossOrigin="anonymous" id="imgPlayerPanel" src={props.config.designPlayerPanel} /> : null}
            {props.config.designPlayerPanelActive ? (
                <img alt="Player panel active" crossOrigin="anonymous" id="imgPlayerPanelActive" src={props.config.designPlayerPanelActive} />
            ) : null}
        </>
    )
}

export default function PlayerEntity(props) {
    let src = ""
    if (props.arrowDirection === "up") src = "#imgPlayerUp"
    else if (props.arrowDirection === "down") src = "#imgPlayerDown"
    else if (props.config.designPlayerPanel) src = "#imgPlayerPanel"
    if (props.active) src = "#imgPlayerPanelActive"

    return (
        <Entity
            data-desc={src}
            data-num={props.num || ""}
            primitive="a-plane"
            material={{ transparent: true, src: src }}
            position={props.position}
            width={props.width || 4}
            height={props.height || 0.7}
            className="clickable"
            events={props.events}
        >
            {!props.arrowDirection ? (
                <Entity primitive="a-text" material={{ transparent: false }} text={{ value: props.text || "Loading..." }} position={{ x: -1.45, z: 0.1 }} />
            ) : null}
        </Entity>
    )
}
