// import "aframe"
import { Entity } from "aframe-react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { fetchPlayersApiData } from "../../store/players-api-slice"
import PlayerEntity from "./PlayerEntity"
import StatsBoxEntity from "./StatsBoxEntity"

export default function PlayerBoxEntity(props) {
    const dispatch = useDispatch()
    const playersApi = useSelector((state) => state.playersApi)
    const [playerActive, setPlayerActive] = useState("-1")
    const [currentList, setCurrentList] = useState([0, 1, 2, 3, 4, 5])
    const [selectedStats, setSelectedStats] = useState({})
    const { sportId } = useParams()
    useEffect(() => {
        if (!playersApi.loaded) dispatch(fetchPlayersApiData(sportId))
    }, [playersApi.loaded, sportId, dispatch])

    const handleSportsUpClicked = () => {
        setPlayerActive("-1")
        setSelectedStats({})
        if (currentList[0] > 0) setCurrentList(currentList.map((pId) => pId - 1))
    }
    const handleSportsDownClicked = () => {
        setPlayerActive("-1")
        setSelectedStats({})
        if (currentList[5] < Object.keys(playersApi.data).length - 1) setCurrentList(currentList.map((pId) => pId + 1))
    }
    const handleHideBoxClicked = () => {
        setPlayerActive("-1")
        setSelectedStats({})
    }
    const handlePlayerClicked = (e) => {
        setSelectedStats(playersApi.data[e.target.getAttribute("data-num")] || {})
        setPlayerActive(e.target.getAttribute("data-num"))
    }

    return (
        <>
            <Entity data-desc="Player Box Panel" position={props.position}>
                <PlayerEntity config={props.config} position={{ x: 0.8, y: 2.75 }} height={0.5} events={{ click: handleSportsUpClicked }} arrowDirection="up" />
                <PlayerEntity
                    config={props.config}
                    num="0"
                    active={playerActive === "0"}
                    text={playersApi.loaded ? playersApi.data[currentList[0]]?.name : ""}
                    position={{ x: 0.8, y: 1.97 }}
                    height={0.7}
                    events={{ click: handlePlayerClicked }}
                />
                <PlayerEntity
                    config={props.config}
                    num="1"
                    active={playerActive === "1"}
                    text={playersApi.loaded ? playersApi.data[currentList[1]]?.name : ""}
                    position={{ x: 0.8, y: 1.19 }}
                    height={0.7}
                    events={{ click: handlePlayerClicked }}
                />
                <PlayerEntity
                    config={props.config}
                    num="2"
                    active={playerActive === "2"}
                    text={playersApi.loaded ? playersApi.data[currentList[2]]?.name : ""}
                    position={{ x: 0.8, y: 0.4 }}
                    height={0.7}
                    events={{ click: handlePlayerClicked }}
                />
                <PlayerEntity
                    config={props.config}
                    num="3"
                    active={playerActive === "3"}
                    text={playersApi.loaded ? playersApi.data[currentList[3]]?.name : ""}
                    position={{ x: 0.8, y: -0.4 }}
                    height={0.7}
                    events={{ click: handlePlayerClicked }}
                />
                <PlayerEntity
                    config={props.config}
                    num="4"
                    active={playerActive === "4"}
                    text={playersApi.loaded ? playersApi.data[currentList[4]]?.name : ""}
                    position={{ x: 0.8, y: -1.19 }}
                    height={0.7}
                    events={{ click: handlePlayerClicked }}
                />
                <PlayerEntity
                    config={props.config}
                    num="5"
                    active={playerActive === "5"}
                    text={playersApi.loaded ? playersApi.data[currentList[5]]?.name : ""}
                    position={{ x: 0.8, y: -1.97 }}
                    height={0.7}
                    events={{ click: handlePlayerClicked }}
                />
                <PlayerEntity config={props.config} position={{ x: 0.8, y: -2.75 }} height={0.5} events={{ click: handleSportsDownClicked }} arrowDirection="down" />
            </Entity>
            {Object.keys(selectedStats).length ? <StatsBoxEntity config={props.config} selectedStats={selectedStats} handleHideBoxClicked={handleHideBoxClicked} /> : null}
        </>
    )
}
