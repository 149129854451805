import { useEffect, useState } from "react"
import { Button, Form, Spinner, Table } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import Layout from "../components/Layout/Layout"
import { fetchProfileData, updateProfile } from "../store/profile-slice"

export default function Profile() {
    const dispatch = useDispatch()
    const profile = useSelector((state) => state.profile)
    const [isEditing, setEditing] = useState(false)
    const [isSubmitting, setSubmitting] = useState(false)
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [address, setAddress] = useState("")
    const [address2, setAddress2] = useState("")

    useEffect(() => {
        if (!profile.loaded) dispatch(fetchProfileData())
    }, [profile.loaded, dispatch])

    const handlerEditProfile = (editing) => {
        setEditing(editing)
        setFirstName(profile.data.first_name)
        setLastName(profile.data.last_name)
        setEmail(profile.data.email)
        setPhone(profile.data.phone)
        setAddress(profile.data.address)
        setAddress2(profile.data.address2)
    }

    const handlerSubmitForm = async () => {
        setSubmitting(true)
        await dispatch(
            updateProfile({
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone: phone,
                address: address,
                address2: address2
            })
        )
        setEditing(false)
        setSubmitting(false)
    }

    if (profile.error) {
        // console.log(profile.error)
        return (
            <div className="d-flex justify-content-center align-items-center mt-5">
                <div className="alert alert-danger px-5">Sorry, you were logged out. Please refresh and try again.</div>
            </div>
        )
    }

    let profileTable = (
        <div className="d-flex justify-content-center">
            <Spinner animation="border" />
        </div>
    )
    if (profile.loaded && profile.data.username) {
        profileTable = (
            <div className="container">
                <h3>My Profile</h3>
                <Form>
                    <Table striped bordered hover size="sm" className="mt-4">
                        <colgroup>
                            <col style={{ width: "50%" }} />
                            <col style={{ width: "50%" }} />
                        </colgroup>
                        <tbody>
                            <tr>
                                <td colSpan="2" className="bg-dark text-white">
                                    {profile.data.username}
                                </td>
                            </tr>
                            <tr>
                                <td>First Name</td>
                                <td>
                                    {isEditing ? (
                                        <input className="form-control" type="text" id="first_name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                    ) : (
                                        profile.data.first_name
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>Last Name</td>
                                <td>
                                    {isEditing ? (
                                        <input className="form-control" type="text" id="last_name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                    ) : (
                                        profile.data.last_name
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>
                                    {isEditing ? (
                                        <input className="form-control" type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    ) : (
                                        profile.data.email
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" className="bg-dark text-white">
                                    Additional Information
                                </td>
                            </tr>
                            <tr>
                                <td>Phone</td>
                                <td>
                                    {isEditing ? (
                                        <input className="form-control" type="text" id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                                    ) : (
                                        profile.data.phone
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>Address</td>
                                <td>
                                    {isEditing ? (
                                        <>
                                            <input
                                                className="form-control"
                                                type="text"
                                                id="address"
                                                value={address}
                                                onChange={(e) => setAddress(e.target.value)}
                                                placeholder="Street Address"
                                            />
                                            <input
                                                className="form-control"
                                                type="text"
                                                id="address2"
                                                value={address2}
                                                onChange={(e) => setAddress2(e.target.value)}
                                                placeholder="Suburb, State Postcode"
                                            />
                                        </>
                                    ) : (
                                        <>
                                            {profile.data.address}
                                            <br />
                                            {profile.data.address2}
                                        </>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Form>
                {!isEditing ? (
                    <Button variant="warning" onClick={() => handlerEditProfile(true)}>
                        Edit Profile
                    </Button>
                ) : (
                    <>
                        <Button variant="success" onClick={handlerSubmitForm} disabled={isSubmitting}>
                            {isSubmitting ? <Spinner animation="border" style={{ width: "16px", height: "16px" }} /> : "Save"}
                        </Button>
                        {!isSubmitting ? (
                            <Button variant="clear" onClick={() => handlerEditProfile(false)}>
                                Cancel
                            </Button>
                        ) : null}
                    </>
                )}
            </div>
        )
    }
    return <Layout>{profileTable}</Layout>
}
