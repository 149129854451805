// import "aframe"
import { Entity } from "aframe-react"

export function LogoTeamAssets(props) {
    return (
        <>
            {props.config.designTeamNamePanel ? <img alt="Team Panel" crossOrigin="anonymous" id="imgTeamPanel" src={props.config.designTeamNamePanel} /> : null}
            {props.team.logo ? <img alt="Team Logo" crossOrigin="anonymous" id="imgTeamLogo" src={props.team.logo} /> : null}
        </>
    )
}

export default function LogoTeamEntity(props) {
    return (
        <>
            <Entity data-desc="Team Logo" primitive="a-plane" material={{ transparent: true, src: props.team.logo ? "#imgTeamLogo" : "" }} position={{ x: 0.1 }} />
            <Entity
                data-desc="Team Logo Panel"
                primitive="a-plane"
                material={{
                    transparent: true,
                    src: props.config.designTeamNamePanel ? "#imgTeamPanel" : ""
                }}
                position={{ x: 2.7 }}
                width="4"
            >
                <Entity data-desc="Team Logo Text" primitive="a-text" text={{ value: props.team.name }} position={{ x: -1.5, y: -0.07, z: 0.1 }} scale={{ x: 2, y: 2, z: 2 }} />
            </Entity>
        </>
    )
}
